import BaseService from './BaseService';

class DynamicDataService extends BaseService {
    getSolutions = () => this.get('/solutions/?format=json');
    
    getTeamMembers = () => this.get('/team-members/?format=json');
    
    getCertifications = () => this.get('/certifications/?format=json');

    getFaqsWithQuery = (search) => this.get(`/faqs/?id=${search}&format=json`);
    
    getFaqs = () => this.get('/faqs/?format=json');

    getFaqSuggestions = (search) => this.get(`/get-faqs-suggestions/?search=${search}&format=json`);
    
    getLiteratureWithQuery = (search) => this.get(`/get-literature-data/?search=${search}&format=json`);
    
    getLiterature = () => this.get('/get-literature-data/?format=json');
    
    getLiteratureSuggestions = (search) => this.get(`/get-literature-suggestions/?search=${search}&format=json`);
    
    getTechnicalDataWithQuery = (search) => this.get(`/get-technical-data/?search=${search}&format=json`);
    
    getTechnicalData = () => this.get('/get-technical-data/?format=json');
    
    getTechnicalDataSuggestions = (search) => this.get(`/get-technical-data-suggestions/?search=${search}&format=json`);
    
    downloadPdf = (data) => this.post('/download-request/', data);
}

export default new DynamicDataService();
