import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { FinishesIcon, LoadingDataIcon, MaterialsIcon } from "../../../assets/illustrations";
import { ArrowCircleRightLightIcon } from "../../../assets/icons";
import useAnimations from "../../../helpers/animations";

function decodeHtml(html) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
};

function ifHasTable(data) {
    const hasTable = data?.specification_data.find(spec => spec.table_headers);
    return hasTable;
};

function Specifications({ data }) {

    useAnimations(({
        usedFor: 'catalog',
        sections: [
            {
                section: 'section.single-product-body ',
                animations: [
                    { target: 'section.specifications .container h2' },
                ]
            }
        ]
    }));


    return (
        <section className={`specifications bg-dark nav-tab ${ifHasTable(data) && 'has-table'}`} id="Specifications">
            <div className="container">
                <h2 className="font-light">
                    Specifications
                </h2>
                <div className="table-specifications-wrapper d-flex">
                    {data?.specification_data.map((specification) => (
                        <div className="single-table-specification">
                            {
                                specification.title && (
                                    <h5>{ReactHtmlParser(specification.title)}</h5>
                                )
                            }
                            {
                                specification.sub_title && (
                                    <p className="text text-strong">
                                        {ReactHtmlParser(specification.sub_title)}
                                    </p>
                                )
                            }
                            {specification.description && (
                                <div className="description" dangerouslySetInnerHTML={{ __html: decodeHtml(specification.description) }} />
                            )}
                            {specification.table_headers && (
                                <div>
                                    <div className="table-container">
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                {specification.table_headers?.map((row) => (
                                                    <tr key={row.id}>
                                                        {row.headers.map((header, index) => (
                                                            header.colspan === 1 ?
                                                                <th key={index} rowSpan={header.rowspan}>{header.name}</th>
                                                                :
                                                                <th
                                                                    key={index}
                                                                    colSpan={header.colspan}
                                                                >
                                                                    {header.name}
                                                                </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody>
                                                {specification.table_data?.map((row, index) => (
                                                    <tr key={index}>
                                                        {Object.keys(row).map((colName, colIndex) => (
                                                            colName !== 'product_id' && colName !== 'id' &&
                                                                <td key={colIndex}>{row[colName]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                            {specification.notes && (
                                <div className="single-table-specification__text">
                                    {ReactHtmlParser(specification.notes)}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="cta d-flex">
                    <div className="left">
                        {data?.specification_notes && (
                            <>
                                <h6>NOTES</h6>
                                <p>{ReactHtmlParser(data?.specification_notes)}</p>
                            </>
                        )}
                    </div>
                    <button className="request outline-btn style-2">
                        <a href="/contact">
                            <span>Request Quote</span>
                            <ArrowCircleRightLightIcon />
                        </a>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Specifications;
