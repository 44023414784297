import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ChevronRightPurpleIcon, HomePurpleIcon } from "../../../assets/icons";
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import StickyNavigation from "../../../components/StickyNavigation";
import SubscriptionDownloadForm from "../../../components/SubscriptionDownloadForm";
import useAnimations from "../../../helpers/animations";
import { actions } from "../../../redux/catalog/reducer";
import NotFound from "../../NotFound";
import OverView from "./OverView";
import Specifications from "./Specifications";
import TechInfo from "./TechInfo";
import Loading from "../../HomePage/Loading";

function SingleProduct() {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const category = queryParams.get('category');
    const { productName } = useParams();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [linkToDownload, setLinkToDownload] = useState('');

    const data = useSelector((state) => state.catalog.productDetails);
    const overview = data?.overview;
    const specifications = data?.specifications;
    const technicalInfo = data?.technical_information;
    const error = useSelector((state) => state.catalog.error);

    useEffect(() => {
        dispatch(actions.requestViewProduct({
            product_id: id,
            product_name: productName,
            category_id: category,
        }));
    }, [dispatch, id, category]);

    useEffect(() => {
        if (data) {
            setDataLoaded(true);
        }
    }, [data]);

    if (error) {
        return <NotFound />;
    } else if (!dataLoaded) {
        return (
            <Loading />
        )
    } else {
        return (
            <div className="single-product-page">
                <HeaderFixed activeTab="catalog" />
                {dataLoaded && <SubscriptionDownloadForm linkToDownload={linkToDownload} />}
                <section className="single-product-upper">
                    <div className="container">
                        <div className="product-breadcrumb d-flex">
                            <div className="icon">
                                <a href="/"><HomePurpleIcon /></a>
                            </div>
                            <div className="text d-flex">
                                {data?.categories.map((category) => (
                                    <>
                                        <div>
                                            {category.name === "Catalog"
                                                ? (
                                                    <a href="/catalog/category">
                                                        {category.name}
                                                    </a>
                                                ) : (
                                                    <a href={`/catalog/category/${category.slug}`}>
                                                        {category.name}
                                                    </a>
                                                )
                                            }
                                        </div>
                                        <div className="arrow">
                                            <ChevronRightPurpleIcon />
                                        </div>
                                    </>
                                ))}
                                <div>{data?.name}</div>
                            </div>
                        </div>
                        <h1 className="font-dark">{data?.name}</h1>
                    </div>
                </section>
                <section className="single-product-body">
                    <StickyNavigation
                        bodyClass=".single-product-body"
                        usedFor="catalog"
                        sectionElement="section.single-product-body"
                        targetElements={[{ target: '.navigation-sticky-container' }]}
                    >
                        <a href="#Overview" className="single-navigation active">Overview </a>
                        {specifications?.specification_data.length !== 0 &&
                            <a href="#Specifications" className="single-navigation ">Specifications </a>
                        }
                        {technicalInfo?.technical_information_data.length !== 0 &&
                            <a href="#Technical-Information" className="single-navigation ">Technical Information </a>
                        }
                    </StickyNavigation>
                    <OverView data={overview} setLinkToDownload={setLinkToDownload} />
                    {specifications?.specification_data.length !== 0 &&
                        <Specifications data={specifications} />}
                    {technicalInfo?.technical_information_data.length !== 0 &&
                        <TechInfo data={technicalInfo} />}
                </section>
            </div>
        )
    }
};

export default SingleProduct;
