import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import Swiper from 'swiper/bundle';
import { Link, useLocation } from "react-router-dom";
import { ArrowLeftDarkMdIcon, ArrowRightDarkMdIcon, PlayVideoIcon } from "../../../assets/icons";
// import NewsVideo from "../../../assets/videos/video-sample.mp4";
import { actions } from "../../../redux/news/reducer";
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import useAnimations from "../../../helpers/animations";

function decodeHtml(html) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
}

function NewsSingle() {
    const dispatch = useDispatch();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('newsId');

    const video = false;
    const news = useSelector(state => state.news.news);
    const singleNews = useSelector(state => state.news.singleNews);

    const decodedHtml = decodeHtml(singleNews?.content);

    useEffect(() => {
        dispatch(actions.requestGetSingleNews(id));
        dispatch(actions.requestGetNews());
    }, [dispatch, id]);

    useEffect(() => {
        const swiperNews = new Swiper('.swiper-news', {
            speed: 500,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1.3,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                },

                992: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 3.5,
                    spaceBetween: 40,
                },
                // when window width is >= 480px
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 47,
                },
                // when window width is >= 640px
                1600: {
                    slidesPerView: 4.5,
                    spaceBetween: 47,
                }
            }
        });
    }, []);

    useAnimations({
        usedFor: video ? 'newsvideo' : 'newssingle',
        sections: [
            {
                section: 'section.news-single-body',
                animations: [
                    { target: '.container .text-content' },
                ]
            },
            {
                section: 'section.news-single-slider',
                animations: [
                    { target: '.container h2' },
                    { target: '.container .swiper' },
                   
                ]
            },
        ]
    });

    return (
        <div className="news-single-page">
            <HeaderFixed activeTab="resources" subMenuActive="news" />
            <section className={`news-single-body ${video && 'news-video'}`}>
                <div className="container">
                    {video ? (
                        <>
                            <h2 className="font-dark">
                                Haydon Corporation announces plans to expand in the Midwest market
                            </h2>
                            <div className="video-banner">
                                {/* <video src={NewsVideo} controls /> */}
                            </div>
                        </>
                    ) : (
                        <div className="banner d-flex">
                            <div className="bg-image"><img src={singleNews?.cover_media} alt="" /></div>
                            <div className="category">
                                Press Releases
                            </div>
                            <div className="text">
                                <div className="date">{singleNews?.publish_date}</div>
                                <div className="divider"></div>
                                <h2 className="font-light">{singleNews?.title}</h2>
                            </div>
                        </div>
                    )}
                    <div className="text-content">
                        <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />
                    </div>
                </div>
            </section>
            <section className="news-slider news-single-slider bg-light">
                <div className="container">
                    <h2 className="font-dark">Related Articles</h2>
                    <div className="swiper swiper-news">
                        <div className="swiper-wrapper">
                            {news?.slice(0, 10).map((newsItem) => (
                                <div key={newsItem.id} className="swiper-slide">
                                    <div className="bg-image">
                                        <img src={newsItem.cover_media} alt="news-cover" />
                                    </div>
                                    <div className="inner-content d-flex">
                                        <div className="upper">
                                            {newsItem.is_video ? (
                                                <div className="play-video">
                                                    <a
                                                        href={`/news-single?${newsItem.title.split(' ').join('-').toLowerCase()}&newsId=${newsItem.id}`}
                                                        className="d-flex"
                                                    >
                                                        <PlayVideoIcon />
                                                        <span>Watch Video</span>
                                                    </a>
                                                </div>
                                            ) : (
                                                <div className="category">{newsItem.tags[0]}</div>
                                            )}
                                        </div>
                                        <div className="bottom d-flex">
                                            <div className="date">{newsItem.publish_date}</div>
                                            <div className="divider"></div>
                                            <p>{newsItem.title}</p>
                                            <button
                                                className="outline-btn style-2"
                                            >
                                                <a href={`/news-single?${newsItem.title.split(' ').join('-').toLowerCase()}&newsId=${newsItem.id}`}>
                                                    Read more
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="swiper-button-prev">
                            <ArrowLeftDarkMdIcon className="news-slider-arrow" />
                        </div>
                        <div className="swiper-button-next">
                            <ArrowRightDarkMdIcon className="news-slider-arrow"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NewsSingle;