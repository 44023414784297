import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import StaticDataService from '../../services/StaticDataService';
import { types, actions } from './reducer';

function* getStaticData() {
    try {
        const response = yield call(StaticDataService.getStaticData);
        const {
            home_page: homePage,
            mainpage: mainPage,
            about_page: aboutPage,
            solutions_page: solutionsPage,
            faqs_page: faqsPage,
            technical_data_page: technicalDataPage,
            contact_page: contactPage,
            catalog_modal: catalogModal,
            products_by_sector_modal: productsBySectorModal,
            catalog_page: catalogPage,
            privacy_page: privacyPage,
            terms_and_conditions_page: termsAndConditionsPage,
            literature_page: literaturePage,
            cross_reference_page: crossReferencePage,
            certifications_page: certificationsPage,
            news_page: newsPage,
        } = response.data;
        yield put(actions.getStaticDataSucceeded(
            homePage,
            mainPage,
            aboutPage,
            solutionsPage,
            faqsPage,
            technicalDataPage,
            contactPage,
            catalogModal,
            productsBySectorModal,
            catalogPage,
            privacyPage,
            termsAndConditionsPage,
            literaturePage,
            crossReferencePage,
            certificationsPage,
            newsPage,
        ));
    } catch (error) {
        yield put(actions.getStaticDataFailed(extractError(error)));
    }
}

function* watchGetStaticData() {
    yield takeEvery(types.REQUEST_GET_STATIC_DATA, getStaticData);
}

function* staticDataSaga() {
    yield all([
        fork(watchGetStaticData),
    ]);
}

export default staticDataSaga;

