import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import CrossReferenceService from '../../services/CrossReferenceService';
import { types, actions } from './reducer';

function* getCrossReferenceProductList({ filters }) {
    const { search } = filters;
    if (filters.search) {
        try {
            const response = yield call(CrossReferenceService.getCrossReferenceProductListWithQuery, search);
            yield put(actions.getCrossReferenceProductListSucceeded(response.data));
        } catch (error) {
            yield put(actions.getCrossReferenceProductListFailed(extractError(error)));
        }
    } else {
        try {
            const response = yield call(CrossReferenceService.getCrossReferenceProductList);
            yield put(actions.getCrossReferenceProductListSucceeded(response.data));
        } catch (error) {
            yield put(actions.getCrossReferenceProductListFailed(extractError(error)));
        }
    }
}

function* getCrossReferenceProductSuggestions({ filters }) {
    try {
        const response = yield call(CrossReferenceService.getCrossReferenceProductSuggestions, filters);
        yield put(actions.getCrossReferenceProductSuggestionsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getCrossReferenceProductSuggestionsFailed(extractError(error)));
    }
}

function* watchGetCrossReferenceProductList() {
    yield takeEvery(types.REQUEST_GET_CROSS_REFERENCE_PRODUCT_LIST, getCrossReferenceProductList);
}

function* watchGetCrossReferenceProductSuggestions() {
    yield takeEvery(types.REQUEST_GET_CROSS_REFERENCE_PRODUCT_SUGGESTIONS, getCrossReferenceProductSuggestions);
}

function* crossReferenceSaga() {
    yield all([
        fork(watchGetCrossReferenceProductList),
        fork(watchGetCrossReferenceProductSuggestions),
    ]);
}

export default crossReferenceSaga;
