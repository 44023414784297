import React, { useEffect } from 'react';
import gsap from 'gsap';
import { NoItemFoundIcon } from "../../assets/icons";

function NoProductsFound() {
    useEffect(() => {
        let entryTl = gsap.timeline();
        entryTl.fromTo('.catalog-body .container .main-content',{},{
            opacity:1,
            y:0,
            duration:0.5
        },"<")
    }, []);

    return (
        <div className="main-content d-flex">
            <div className="empty-product-list">
                <NoItemFoundIcon />
                <h3>No Items Were Found</h3>
                <p className="info-text">Please navigate to other categories!</p>
            </div>
        </div>
    )
}

export default NoProductsFound;
