import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import {
    ArrowCircleDropdownIcon,
    CalendarTimeLightIcon,
    HearLightIcon,
    SearchDarkIcon,
    CloseMobileIcon,
} from '../../assets/icons';
import { actions } from '../../redux/catalog/reducer';

const returnParentClassName = ((prefix, parentId, category) => {
    let parentClassName = `${prefix} ${parentId}`;
    category.children_categories?.forEach((childCategory) => {
        parentClassName += ` ${childCategory.id}`;
    });
    return parentClassName;
});

const addTag = (category, childCategory, checkedCategories) => {
    const existingItem = checkedCategories.findIndex(item => item.id === category.id);

    if (existingItem !== -1) {
        checkedCategories[existingItem].children.push({
            id: childCategory.id,
            name: childCategory.name,
        });
    } else {
        checkedCategories.push({
            id: category.id,
            name: category.name,
            children: [{
                id: childCategory.id,
                name: childCategory.name,
            }]
        });
    }
    return checkedCategories;
};

const addAllMainCategoryTags = (category, checkedCategories) => {
    const existingItem = checkedCategories.findIndex(item => item.id === category.id);

    if (existingItem !== -1) {
        checkedCategories[existingItem].children.push(...category.children_categories?.map((subCategory) => (
            subCategory.children_categories ? (
                subCategory.children_categories?.map((childCategory) => (
                    {
                        id: childCategory.id,
                        name: childCategory.name
                    }
                ))
            ) : (
                {
                    id: subCategory.id,
                    name: subCategory.name
                }
            )
        ))?.flat(1));
    } else {
        checkedCategories.push({
            id: category.id,
            name: category.name,
            children: category.children_categories?.map((subCategory) => (
                subCategory.children_categories ? (
                    subCategory.children_categories?.map((childCategory) => (
                        {
                            id: childCategory.id,
                            name: childCategory.name
                        }
                    ))
                ) : (
                    {
                        id: subCategory.id,
                        name: subCategory.name
                    }
                )
            ))?.flat(1)
        });
    }
    return checkedCategories;
};

const addTags = (category, subCategory, checkedCategories) => {
    const existingItem = checkedCategories.findIndex(item => item.id === category.id);

    if (existingItem !== -1) {
        // subCategory.children_categories
        //     ? (
        //         checkedCategories[existingItem].children.push(...subCategory.children_categories?.map((childCategory) => (
        //             {
        //                 id: childCategory.id,
        //                 name: childCategory.name
        //             }
        //         ))?.flat(1))
        //     ) : (
        //         checkedCategories[existingItem].children.push({
        //             id: subCategory.id,
        //             name: subCategory.name
        //         })
        //     );
        checkedCategories[existingItem].children.push({
            id: subCategory.id,
            name: subCategory.name
        });
    } else {
        // checkedCategories.push({
        //     id: category.id,
        //     name: category.name,
        //     children:
        //     subCategory.children_categories
        //         ? (
        //             subCategory.children_categories?.map((childCategory) => (
        //                 {
        //                     id: childCategory.id,
        //                     name: childCategory.name
        //                 }
        //             ))
        //         ) : (
        //             [{
        //                 id: subCategory.id,
        //                 name: subCategory.name
        //             }]
        //         )
        // });
        checkedCategories.push({
            id: category.id,
            name: category.name,
            children:
                [{
                    id: subCategory.id,
                    name: subCategory.name
                }]
        });
    }
    return checkedCategories;
};

const removeTags = (category, subCategory, checkedCategories) => {
    const existingItem = checkedCategories.findIndex(item => item.id === category.id);

    if (existingItem !== -1) {
        subCategory.children_categories
            ? (
                checkedCategories[existingItem].children = checkedCategories[existingItem].children
                    .filter(child => !subCategory.children_categories?.map((childCategory) => childCategory.id).includes(child.id))
            ) : (
                checkedCategories[existingItem].children = checkedCategories[existingItem].children
                    .filter(child => child.id !== subCategory.id)
            );
    }
    return checkedCategories;
};

function SideBar({
    catalogCategories,
    checkedCategories,
    setCheckedCategories,
    catalogUrlList,
}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const [search, setSearch] = useState(queryParams.get('search') || '');

    const removeSlugFromUrl = (matchingItem) => {
        // Get all segments, ignoring empty segments (from leading/trailing slashes)
        const urlObject = new URL(window.location.href);
        const pathname = urlObject.pathname;
        const pathSegments = pathname.split('/').filter(Boolean);

        // const indexToRemove = 1; // Alternatively, remove by index
        const indexOfSegment = pathSegments.indexOf(matchingItem.slug);
    
        if (indexOfSegment !== -1) {
            // Remove the segment by index
            pathSegments.splice(indexOfSegment, 1);
        }
    
        // Construct the new path
        const newPath = `/${pathSegments.join('/')}`;
        navigate(`${newPath}`);
    };

    const urlObject = new URL(window.location.href);
    const pathname = urlObject.pathname;

    // The prefix to be removed from the pathname
    const prefix = '/catalog/category/';
    const targetPath = pathname.replace(prefix, '');

    // Split the remaining path into a list of strings
    const pathList = targetPath.split('/');

    const matchingItemList = [];
    for (const path of pathList) {
        // Search for the matching slug
        const matchingItem = catalogUrlList.find(item => item.slug === path);
        if (matchingItem) {
            matchingItemList.push(matchingItem.id.toString());
        }
    };

    const queryIds = matchingItemList;

    // add quick search to query params
    const searchProductList = (value) => {
        setSearch(value);
        if (value === '') {
            queryParams.delete('search');
            navigate(`?${queryParams.toString()}`);
            return;
        }
        queryParams.set('search', value);
        navigate(`?${queryParams.toString()}`);
    };

    // get product list from query params
    useEffect(() => {
        const data = {
            category_ids: queryIds,
            quick_search: queryParams.get('search'),
        };
        queryIds.length !== 0
        && dispatch(actions.requestGetProductList(data));
    }, [location.search, queryIds]);

    // check if url-ids exist in the checkedCategories
    useEffect(() => {
        const mainIds = catalogCategories?.map((category) => category.id) || [];
        const subIds = catalogCategories?.flatMap((category) => category.children_categories?.map((subCategory) => subCategory.id) || []);
        const childIds = catalogCategories?.flatMap((category) => category.children_categories?.flatMap((subCategory) => subCategory.children_categories?.map((childCategory) => childCategory.id)) || []);
        
        const allIds = [1, ...mainIds, ...subIds, ...childIds].filter(item => item !== undefined);

        if (queryIds.length !== 0 && allIds.length !== 0) {
            queryIds.forEach(queryId => {
                // Check if the id exists in allIds
                const parsedQueryId = parseInt(queryId, 10);
                if (!allIds.includes(parsedQueryId)) {
                    // If it doesn't exist, reload the page to /catalog
                    navigate('/catalog/category');
                }
            });
        }
    }, [catalogCategories]);

    // handle checkbox change on page load
    useEffect(() => {
        const mainCategories = document.querySelectorAll(`.category-block-wrapper .category-block .main-category input[type="checkbox"]`);
        const subCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .sub-category .sub`);
        const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .category input[type="checkbox"]`);
    
        const mainCategoryValues = Array.from(mainCategories)?.map((mainCategory) => (
            {
                value: mainCategory.value,
                name: mainCategory.name,
                checked: mainCategory.checked,
            }
        ));
        const subCategoryValues = Array.from(subCategories)?.map((subCategory) => (
            {
                value: subCategory.value,
                name: subCategory.name,
                checked: subCategory.checked,
            }
        ));
        const childCategoryValues = Array.from(childCategories)?.map((childCategory) => (
            {
                value: childCategory.value,
                name: childCategory.name,
                checked: childCategory.checked,
            }
        ));
    
        const anyChildInUrl = (childCategories, queryIds) => {
            let ifAnyChildInUrl = false;
            childCategories.forEach((childCategory) => {
                if (queryIds.includes(childCategory.value)) {
                    ifAnyChildInUrl = true;
                    return ifAnyChildInUrl;
                }
            });
            return ifAnyChildInUrl;
        };

        const anyChildOfSubCategoryInUrl = (subCategories, queryIds) => {
            let ifAnyChildInUrl = false;
            subCategories.forEach((subCategory) => {
                const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${subCategory.value}`);
                childCategories.forEach((childCategory) => {
                    if (queryIds.includes(childCategory.value)) {
                        ifAnyChildInUrl = true;
                        return ifAnyChildInUrl;
                    }
                });
            });
            return ifAnyChildInUrl;
        };
        mainCategoryValues.length  !== 0 && 
        queryIds.forEach((id) => {
            const mainCategory = mainCategoryValues?.find((mainCategory) => mainCategory.value === id);
            const subCategory = subCategoryValues?.find((subCategory) => subCategory.value === id);
            const childCategory = childCategoryValues?.find((childCategory) => childCategory.value === id);
            if (mainCategory) {
                const mainCategoryEl = document.getElementById(mainCategory.value);
                mainCategoryEl.checked = true;
                checkedCategories.push({
                    id: parseInt(mainCategory.value, 10),
                    name: mainCategory.name,
                    children: []
                });
                const subCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .s-${id}`);
                const ifAnySubInUrl = anyChildInUrl(subCategories, queryIds);
                if (ifAnySubInUrl) {
                    subCategories.forEach((subCategory) => {
                        if (queryIds.includes(subCategory.value)) {
                            subCategory.checked = true;
                            const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${subCategory.value}`);
                            const ifAnyChildInUrl = anyChildInUrl(childCategories, queryIds);
                            if (ifAnyChildInUrl) {
                                childCategories.forEach((childCategory) => {
                                    if (queryIds.includes(childCategory.value)) {
                                        childCategory.checked = true;
                                        checkedCategories[checkedCategories.length - 1].children.push({
                                            id: parseInt(childCategory.value, 10),
                                            name: childCategory.name
                                        });
                                    }
                                });
                            }
                            else {
                            //     childCategories.forEach((childCategory) => {
                            //         childCategory.checked = true;
                            //         checkedCategories[checkedCategories.length - 1].children.push({
                            //             id: parseInt(childCategory.value, 10),
                            //             name: childCategory.name
                            //         });
                            //     });
                                checkedCategories[checkedCategories.length - 1].children.push({
                                    id: parseInt(subCategory.value, 10),
                                    name: subCategory.name
                                });
                            }
                        }
                    });
                } else {
                    const ifAnyChildInUrl = anyChildOfSubCategoryInUrl(subCategories, queryIds);
                    if (ifAnyChildInUrl) {
                        subCategories.forEach((subCategory) => {
                            const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${subCategory.value}`);
                            childCategories.forEach((childCategory) => {
                                if (queryIds.includes(childCategory.value)) {
                                    childCategory.checked = true;
                                    subCategory.checked = true;
                                    checkedCategories[checkedCategories.length - 1].children.push({
                                        id: parseInt(childCategory.value, 10),
                                        name: childCategory.name
                                    });
                                }
                            });
                        });
                    }
                    // else {
                    //     subCategories.forEach((subCategory) => {
                    //         subCategory.checked = true;
                    //         const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${subCategory.value}`);
                    //         childCategories.forEach((childCategory) => {
                    //             childCategory.checked = true;
                    //             checkedCategories[checkedCategories.length - 1].children.push({
                    //                 id: parseInt(childCategory.value, 10),
                    //                 name: childCategory.name
                    //             });
                    //         });
                    //         if (childCategories.length === 0) {
                    //             checkedCategories[checkedCategories.length - 1].children.push({
                    //                 id: parseInt(subCategory.value, 10),
                    //                 name: subCategory.name
                    //             });
                    //         }
                    //     });
                    // }
                }
            }
            else if (subCategory) {
                const subCategoryEl = document.getElementById(subCategory.value);
                subCategoryEl.checked = true;
                const parent = document.getElementsByClassName(`main ${id}`)[0];
                if (parent) {
                    parent.checked = true;
                    const existingItem = checkedCategories.findIndex(item => item.id === parseInt(parent.value, 10));
                    if (existingItem !== -1) {
                        checkedCategories = [...checkedCategories]
                    } else {
                        checkedCategories.push({
                            id: parseInt(parent.value, 10),
                            name: parent.name,
                            children: []
                        });
                    }
                }
                const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${id}`);
                const ifAnyChildInUrl = anyChildInUrl(childCategories, queryIds);
                if (ifAnyChildInUrl) {
                    childCategories.forEach((childCategory) => {
                        if (queryIds.includes(childCategory.value)) {
                            childCategory.checked = true;
                            if (checkedCategories[checkedCategories.length - 1].children.findIndex(item => item.id === parseInt(childCategory.value, 10)) === -1) {
                                checkedCategories[checkedCategories.length - 1].children.push({
                                    id: parseInt(childCategory.value, 10),
                                    name: childCategory.name
                                });
                            }
                        }
                    });
                }
                else {
                //     childCategories.forEach((childCategory) => {
                //         childCategory.checked = true;
                //         if (checkedCategories[checkedCategories.length - 1].children.findIndex(item => item.id === parseInt(childCategory.value, 10)) === -1) {
                //             checkedCategories[checkedCategories.length - 1].children.push({
                //                 id: parseInt(childCategory.value, 10),
                //                 name: childCategory.name
                //             });
                //         }
                //     });
                    if (checkedCategories[checkedCategories.length - 1].children.findIndex(item => item.id === parseInt(subCategory.value, 10)) === -1) {
                        checkedCategories[checkedCategories.length - 1].children.push({
                            id: parseInt(subCategory.value, 10),
                            name: subCategory.name
                        });
                    }
                }
            } else {
                const childCategoryEl = document.getElementById(childCategory?.value);
                if (childCategoryEl)
                    childCategoryEl.checked = true;
                const parent = document.getElementsByClassName(`sub ${id}`)[0];
                if (parent) {
                    parent.checked = true;
                    const grandParent = document.getElementsByClassName(`main ${parent.value}`)[0];
                    if (grandParent) {
                        grandParent.checked = true;
                        const existingItem = checkedCategories.findIndex(item => item.id === parseInt(grandParent.value, 10));
                        if (existingItem !== -1) {
                            if (checkedCategories[existingItem].children.findIndex(item => item.id === parseInt(childCategory.value, 10)) === -1) {
                                checkedCategories[existingItem].children.push({
                                    id: parseInt(childCategory.value, 10),
                                    name: childCategory.name
                                });
                            }
                        } else {
                            checkedCategories.push({
                                id: parseInt(grandParent.value, 10),
                                name: grandParent.name,
                                children: [{
                                    id: parseInt(childCategory.value, 10),
                                    name: childCategory.name
                                }]
                                    
                            })
                        }
                    }
                }
            }
        });
        setCheckedCategories(checkedCategories);
    }, [catalogCategories]);

    // handle checkbox change
    const checkAllCheckboxes = (checked, categoryId, mainCategory, mainCategoryId) => {
        if (checked) {
            if (mainCategory) {
                if (!matchingItemList.includes(categoryId.toString())) {
                    const matchingItem = catalogUrlList.find(item => item.id === categoryId);
                    const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
                    if (!matchingItemSlugUrl) {
                        navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
                    }
                }
            } else {
                if (!matchingItemList.includes(mainCategoryId.toString())) {
                    const matchingItem = catalogUrlList.find(item => item.id === mainCategoryId);
                    const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
                    if (!matchingItemSlugUrl) {
                        navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
                    }
                }
                if (!matchingItemList.includes(categoryId.toString())) {
                    const matchingItem = catalogUrlList.find(item => item.id === categoryId);
                    const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
                    if (!matchingItemSlugUrl) {
                        navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
                    }
                }
            }
        } else {
            const matchingItem = catalogUrlList.find(item => item.id === categoryId);
            const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            if (matchingItemSlugUrl) {
                removeSlugFromUrl(matchingItem);
            }
        }

        if (mainCategory) {
            console.log('mainCategory');
            // const subCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .s-${categoryId}`);
            // subCategories.forEach((subCategory) => {
            //     subCategory.checked = checked;
            //     if (checked) {
            //         if (!matchingItemList.includes(subCategory.value)) {
            //             const matchingItem = catalogUrlList.find(item => item.id === parseInt(subCategory.value, 10));
            //             const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            //             if (!matchingItemSlugUrl) {
            //                 navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
            //             }
            //         }
            //     } else {
            //         const matchingItem = catalogUrlList.find(item => item.id === parseInt(subCategory.value));
            //         const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            //         if (matchingItemSlugUrl) {
            //             removeSlugFromUrl(matchingItem);
            //         }
            //     }
            //     const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${subCategory.value}`);
            //     childCategories.forEach((childCategory) => {
            //         childCategory.checked = checked;
            //         if (checked) {
            //             if (!matchingItemList.includes(childCategory.value)) {
            //                 const matchingItem = catalogUrlList.find(item => item.id === parseInt(childCategory.value, 10));
            //                 const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            //                 if (!matchingItemSlugUrl) {
            //                     navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
            //                 }
            //             }
            //         } else {
            //             const matchingItem = catalogUrlList.find(item => item.id === parseInt(childCategory.value));
            //             const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            //             if (matchingItemSlugUrl) {
            //                 removeSlugFromUrl(matchingItem);
            //             }
            //         }
            //     });
            // });
        } else {
            const subCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .s-${mainCategoryId}`);
            const checkedSubCategories = Array.from(subCategories).filter((subCategory) => subCategory.checked);
            const parentCategory = document.getElementById(mainCategoryId);
            if (checkedSubCategories.length > 0) {
                // If at least one child is checked, check the parent
                parentCategory.checked = checkedSubCategories.length <= subCategories.length;
            } else {
                // If no child is checked, uncheck the parent
                parentCategory.checked = false;
                const matchingItem = catalogUrlList.find(item => item.id === mainCategoryId);
                const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
                if (matchingItemSlugUrl) {
                    removeSlugFromUrl(matchingItem);
                }
            }

            // const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${categoryId}`);
            // childCategories.forEach((childCategory) => {
            //     childCategory.checked = checked;
            //     if (checked) {
            //         if (!matchingItemList.includes(childCategory.value)) {
            //             const matchingItem = catalogUrlList.find(item => item.id === parseInt(childCategory.value, 10));
            //             const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            //             if (!matchingItemSlugUrl) {
            //                 navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
            //             }
            //         }
            //     } else {
            //         const matchingItem = catalogUrlList.find(item => item.id === parseInt(childCategory.value));
            //         const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            //         if (matchingItemSlugUrl) {
            //             removeSlugFromUrl(matchingItem);
            //         }
            //     }
            // });
        }
    }

    const handleChildCheckboxChange = (e, categoryId, mainCategoryId) => {
        if (e.target.checked) {
            if (!matchingItemList.includes(mainCategoryId.toString())) {
                const matchingItem = catalogUrlList.find(item => item.id === mainCategoryId);
                const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
                if (!matchingItemSlugUrl) {
                    navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
                }
            }
            if (!matchingItemList.includes(categoryId.toString())) {
                const matchingItem = catalogUrlList.find(item => item.id === categoryId);
                const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
                if (!matchingItemSlugUrl) {
                    navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
                }
            }
            const matchingItem = catalogUrlList.find(item => item.id === parseInt(e.target.value));
            const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            if (!matchingItemSlugUrl) {
                navigate(`${window.location.pathname}/${matchingItem.slug.toLowerCase()}`);
            }
        } else {
            const matchingItem = catalogUrlList.find(item => item.id === parseInt(e.target.value));
            const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            if (matchingItemSlugUrl) {
                removeSlugFromUrl(matchingItem);
            }
        }

        const childCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${categoryId}`);
        const checkedChildCategories = Array.from(childCategories).filter((childCategory) => childCategory.checked);
    
        const parentCategory = document.getElementById(categoryId);
    
        if (checkedChildCategories.length > 0) {
            // If at least one child is checked, check the parent
            parentCategory.checked = checkedChildCategories.length <= childCategories.length;
        } else {
            // If no child is checked, uncheck the parent
            parentCategory.checked = false;
            const matchingItem = catalogUrlList.find(item => item.id === categoryId);
            const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            if (matchingItemSlugUrl) {
                removeSlugFromUrl(matchingItem);
            }
        }

        const subCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .s-${mainCategoryId}`);
        const checkedSubCategories = Array.from(subCategories).filter((subCategory) => subCategory.checked);
        const parentCategory2 = document.getElementById(mainCategoryId);
        if (checkedSubCategories.length > 0) {
            // If at least one child is checked, check the parent
            parentCategory2.checked = checkedSubCategories.length <= subCategories.length;
        } else {
            // If no child is checked, uncheck the parent
            parentCategory2.checked = false;
            const matchingItem = catalogUrlList.find(item => item.id === mainCategoryId);
            const matchingItemSlugUrl = pathList.find(item => item === matchingItem.slug);
            if (matchingItemSlugUrl) {
                removeSlugFromUrl(matchingItem);
            }
            
        }
    };

    // toggle dropdown
    useEffect(() => {
        const menuSubCategories = document.querySelectorAll('.category-block-wrapper .category-blocks .category-block >.head');

        const toggleDropdown = (element, contentSelector) => {
            const $element = $(element); // Convert to jQuery object
            $element.toggleClass('opened');
            $element.find(contentSelector).slideToggle(300);
        };
    
        const handleSubCategoryClick = (event) => {
            const category = event.currentTarget;
            const element = category.closest('.category-block');
            toggleDropdown(element, '>.body');
        };
    
        menuSubCategories.forEach(category => {
            category.addEventListener('click', handleSubCategoryClick);
        });
    
        return () => {
            menuSubCategories.forEach(category => {
                category.removeEventListener('click', handleSubCategoryClick);
            });
        };
    }, [catalogCategories]);

    useEffect(() => {
        // Add class opened for selected checkboxes
        function addOpenedClass() {
            // Get all .category-block elements
            var categoryBlocks = document.querySelectorAll('.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block');

            // Iterate over each .category-block element
            categoryBlocks.forEach(function (categoryBlock) {
                // Check if the corresponding checkbox is checked
                var checkbox = categoryBlock.querySelector('.body input[type="checkbox"]:checked');
                var subCats = categoryBlock.querySelectorAll('.category-block.sub-category');

                if (checkbox) {
                    // If the checkbox is checked, add the 'opened' class to the current .category-block element
                    categoryBlock.classList.add('opened');
                }

                subCats.forEach(function (subCat) {
                    var checkbox2 = subCat.querySelector('.body input[type="checkbox"]:checked');

                    if (checkbox2) {
                        subCat.classList.add('opened');
                    }
                });
            });
        }

        // Call the function when the component is mounted
        addOpenedClass();
    }, [catalogCategories]);

    return (
        <div className="sidebar d-flex">
            <div className="action">
                <form action="">
                    <input
                        type="text"
                        placeholder="Quick search..."
                        name="search"
                        className="searchInput"
                        value={queryParams.get('search') || ''}
                        onChange={(e) => {searchProductList(e.target.value)}}
                    />
                    {search !== '' ?
                        <button
                            type="button"
                            className="clear"
                            onClick={() => {
                                setSearch('');
                                queryParams.delete('search');
                                navigate(`?${queryParams.toString()}`);
                            }}
                        >
                            <CloseMobileIcon />
                        </button>
                        :
                        <button disabled type="submit" className="submit">
                            <SearchDarkIcon />
                        </button>
                    }
                </form>
                {/* <div className="btn-wrapper d-flex">
                    <button className="favorites">
                        <Link to="">
                            <HearLightIcon />
                            <span>My Favorites</span>
                        </Link>
                    </button>
                    <button className="past-projects">
                        <Link to="">
                            <CalendarTimeLightIcon />
                            <span>Past Projects</span>
                        </Link>
                    </button>
                </div> */}
            </div>
            <div className="category-block-wrapper">
                <div className="category-blocks">
                    {catalogCategories?.map((category) => (
                        <div key={category.id} className="category-block">
                            <div className="head main-category">
                                <div className="d-flex">
                                    <input
                                        type="checkbox"
                                        id={category.id}
                                        name={category.name}
                                        className={returnParentClassName('main', category.id, category)}
                                        value={category.id}
                                        onChange={(e) => {
                                            checkAllCheckboxes(e.target.checked, category.id, true);
                                            // if (e.target.checked) {
                                            //     setCheckedCategories(addAllMainCategoryTags(category, checkedCategories));
                                            // } else {
                                            //     setCheckedCategories(checkedCategories.filter((item) => item.id !== category.id));
                                            // }
                                        }}
                                    />
                                    <div className="title">{category.name}</div>
                                </div>
                                <div className="icon">
                                    <div>
                                        <ArrowCircleDropdownIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="body">
                                {category.children_categories.map((subCategory) => (
                                    <div key={subCategory.id} className="category-block sub-category">
                                        <div className="head">
                                            <div className="d-flex">
                                                <input
                                                    type="checkbox"
                                                    id={subCategory.id}
                                                    name={subCategory.name}
                                                    value={subCategory.id}
                                                    className={`s-${category.id} ${returnParentClassName('sub', category.id, subCategory)}`}
                                                    onChange={(e) => {
                                                        checkAllCheckboxes(e.target.checked, subCategory.id, false, category.id);
                                                        if (e.target.checked) {
                                                            setCheckedCategories(addTags(category, subCategory, checkedCategories));
                                                        } else {
                                                            setCheckedCategories(removeTags(category, subCategory, checkedCategories));
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={subCategory.name}>{subCategory.name}</label>
                                            </div>
                                            {subCategory.children_categories && (
                                                <div className="circle">
                                                    <div className="d-flex">
                                                        <div className="plus-minus">
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="body">
                                            {subCategory.children_categories?.map((childCategory) => (
                                                <div key={childCategory.id} className="category">
                                                    <input
                                                        type="checkbox"
                                                        id={childCategory.id}
                                                        name={childCategory.name}
                                                        value={childCategory.id}
                                                        className={`ch-${subCategory.id}`}
                                                        onChange={(e) => {
                                                            handleChildCheckboxChange(e, subCategory.id, category.id)
                                                            if (e.target.checked) {
                                                                setCheckedCategories(addTag(category, childCategory, checkedCategories));
                                                            } else {
                                                                setCheckedCategories(checkedCategories.map(item => {
                                                                    if (item.id === category.id) {
                                                                        item.children = item.children.filter(child => child.id !== childCategory.id);
                                                                    }
                                                                    return item;
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor={childCategory.name}>{childCategory.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
