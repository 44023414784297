import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderFixed from "../../components/MainLayout/HeaderFixed";
import { actions as newsActions } from "../../redux/news/reducer";
import HPBanner from "./HPBanner";
import Resources from "./Resources";
import About from "./About";
import Products from "./Products";
import News from "./News";
import Loading from "./Loading";

function HomePage() {
    const dispatch = useDispatch();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [newsLoaded, setNewsLoaded] = useState(false);
    const [solutionsLoaded, setSolutionsLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const homePage = useSelector((state) => state.staticData.homePage);
    const solutions = useSelector(state => state.dynamicData.solutions);
    const news = useSelector(state => state.news.news);

    useEffect(() => {
        if (homePage) {
            setDataLoaded(true);
        }
        if (news.length > 0) {
            setNewsLoaded(true);
        }
        if (solutions.length > 0) {
            setSolutionsLoaded(true);
        }
        if (solutions.length > 0 && news.length > 0 && homePage) {
            setTimeout(() => setLoading(false), 1000);
            // setLoading(false);
        }
    }, [homePage, news, solutions]);

    useEffect(() => {
        dispatch(newsActions.requestGetNews());
    }, [dispatch]);

    return (
        <div className="home-page">
            {loading && <Loading />}
            {dataLoaded && (
                <>
                    <HeaderFixed activeTab="home" />
                    <HPBanner data={homePage?.section_1} />
                </>
            )}
            {solutionsLoaded && (
                <Products data={homePage?.section_2} solutions={solutions} />
            )}
            {dataLoaded && (
                <>
                    <Resources data={homePage?.section_3} />
                    <About data={homePage?.section_4} />
                </>
            )}
            {newsLoaded && (
                <News data={homePage?.section_5} news={news} />
            )}
        </div>
    );
}

export default HomePage;
