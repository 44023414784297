import { useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

const useAnimations = ({ usedFor, sections }) => {
    useEffect(() => {
        if (usedFor === 'homepage') {
            let entryTl = gsap.timeline();
            entryTl.fromTo('section.entry-banner.hp .text-content', {}, {
                opacity: 1,
                y: 0,
                duration: 1
            })
            if ($(window).width() > 575) {
                entryTl.fromTo('section.entry-banner.hp .scroll', {}, {
                    opacity: 1,

                    duration: 1
                }, "<")
            }
        } else if (usedFor === 'contactpage') {
            let entryTl = gsap.timeline();
            entryTl.fromTo('section.contact-body .container .main-content .left',{},{
                opacity:1,
                y:0,
                duration:0.5
            })
            entryTl.fromTo('section.contact-body .container .main-content .right',{},{
                opacity:1,
                y:0,
                duration:0.5
            },"<")
        } else if (usedFor === 'catalog') {
            gsap.fromTo('.single-product-upper',{},{
                opacity:1,
                y:0,
                duration:0.5
            })
        } else if (usedFor === 'newssingle') {
            gsap.fromTo('.news-single-body .container .banner',{},{
                opacity:1,
                y:0,
                duration:0.5
            })
        } else if (usedFor === 'newsvideo') {
            let entryTl = gsap.timeline();
            entryTl.fromTo('.news-single-body.news-video .container h2',{},{
                opacity:1,
                y:0,
                duration:0.5
            })
            
            entryTl.fromTo('.news-single-body.news-video .container .video-banner',{},{
                opacity:1,
                y:0,
                duration:0.5
            },"<")
        } else if (usedFor === 'entry-banner') {
            let entryTl = gsap.timeline();
            entryTl.fromTo('.entry-banner .text-content',{},{
                opacity:1,
                y:0,
                duration:1
            })
            entryTl.fromTo('.entry-banner .background img',{},{
                scale:1,
                duration:1
            },"<")
        }

        sections.forEach(({ section, animations }) => {
            animations.forEach(({ target, stagger, scale }) => {
                const animation = {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    scrollTrigger: { trigger: `${section}  ${target}` }
                };

                if (stagger !== undefined) {
                    animation.stagger = stagger;
                }

                if (scale !== undefined) {
                    animation.scale = scale;
                }

                gsap.fromTo(`${section}  ${target}`, {}, animation);
            });
        });
        return () => ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    }, [sections, usedFor]);
};

export default useAnimations;
