import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import gsap from "gsap";
import HeaderFixed from "../../components/MainLayout/HeaderFixed";
import SideBar from "./SideBar";
import MainContent from "./MainContent";
import { actions } from "../../redux/catalog/reducer";
import { updateTitle } from "../../helpers/utils";

function Catalog() {
    const dispatch = useDispatch();

    const [checkedCategories, setCheckedCategories] = useState([]);

    const catalogCategories = useSelector((state) => state.catalog.catalogCategories);
    const catalogUrlList = useSelector((state) => state.catalog.catalogUrl);

    useEffect(() => {
        dispatch(actions.requestGetCategories());
    }, [dispatch]);

    useEffect(() => {
        let entryTl = gsap.timeline();
        entryTl.fromTo('.catalog-body .container .sidebar',{},{
            opacity:1,
            y:0,
            duration: 0.5
        })
    }, []);

    useEffect(() => {
        updateTitle('Haydon - Catalog');
    }, []);

    return (
        <div className="catalog-page">
            <HeaderFixed activeTab="catalog" />
            <section className="catalog-body">
                <div className="container">
                    <SideBar
                        catalogCategories={catalogCategories}
                        checkedCategories={checkedCategories}
                        setCheckedCategories={setCheckedCategories}
                        catalogUrlList={catalogUrlList}
                    />
                    <MainContent
                        checkedCategories={checkedCategories}
                        setCheckedCategories={setCheckedCategories}
                        catalogUrlList={catalogUrlList}
                    />
                </div>
            </section>
        </div>
    )
};

export default Catalog;
