function Info({ data }) {
    return (
        <section className="contact-details bg-dark">
            <div className="container">
                <h2 className="font-light">{data?.contact_header.title}</h2>
                <div className="locations d-flex">
                    {data?.locations.map((location) => (
                        <div key={location.id} className="single-detail-wrapper">
                            <div className="single-detail">
                                <a href={location.button_link} target="_blank">
                                    <h6>{location.title}</h6>
                                    <div className="divider"></div>
                                    <p>{location.description}</p>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="contact-info d-flex">
                    {data?.contact_details.map((contact) => (
                        <div key={contact.id} className="single-detail-wrapper">
                            <div className="single-detail">
                                <h6>{contact.title}</h6>
                                <p>
                                    {contact.description.split(',').map((item) => (
                                        < span key={item}>
                                            <a href={contact.title === 'Email' ? `mailto:${item}` : `tel:${item}`}>{item}</a>
                                            <br />
                                        </span>
                                    ))}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Info;