import ReactHtmlParser from "react-html-parser";
import FaqBannerImg from "../../../assets/banner/banner.avif";
import Banner from "../../../components/Banner";
function FaqBanner({ data }) {
    return (
        <Banner
            banner="faq-banner"
            backgroundBanner={FaqBannerImg}
            activeTab="resources"
        >
            <div className="container">
                <div className="text-content d-flex">
                    <h1 className="font-light">
                        {/* Have <span>Questions?</span> */}
                        {ReactHtmlParser(data?.faqs_header.title)}
                    </h1>
                    <div className="text">
                        {/* We have answers. <br /> */}
                        {data?.faqs_header.description.slice(0, 16)}<br />
                        {/* Explore our Frequently Asked Questions for insights on our products, solutions, and essential Haydon
                        information. */}
                        {data?.faqs_header.description.slice(16)}
                    </div>
                </div>
            </div>
        </Banner>
    );
};

export default FaqBanner;
