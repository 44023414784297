import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRightCircleLightSm, RemoveIcon } from "../../assets/icons";
import { actions } from "../../redux/dynamic_data/reducer";

function SubscriptionDownloadForm({ linkToDownload }) {
    const dispatch = useDispatch();

    const message = useSelector((state) => state.dynamicData.message);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        accepted_terms: false,
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const inputFields = document.querySelectorAll(".subscription-download-form input");
        inputFields.forEach((input) => {
            input.type === 'checkbox' ? input.checked = false : input.value = '';
        });

        const downloadModal = document.querySelector(".subscription-download-form");
        if (downloadModal) {
            document.body.classList.remove("download-modal-open");
        }

        dispatch(actions.requestDownloadPdf(formData));
    };

    useEffect(() => {
        if (message === "Success") {
            window.open(linkToDownload, '_blank');
            localStorage.setItem('userSubmissionAccepted', 'true');
        }
    }, [message]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    useEffect(() => {
        const downloadModal = document.querySelector(".subscription-download-form");
    
        if (document.body.contains(downloadModal)) {
            const downloadModalButtons = document.querySelectorAll(".download-form");
            const downloadModalContainer = document.getElementById("subscription-download-form-container");
            const downloadModalClose = document.querySelector(".subscription-download-form .close");
        
            const openDownloadModal = () => {
                document.body.classList.add("download-modal-open");
            };
        
            const closeDownloadModal = () => {
                document.body.classList.remove("download-modal-open");
            };
        
            const handleClickOutside = (event) => {
                if (event.target === downloadModal || event.target === downloadModalContainer) {
                    closeDownloadModal();
                }
            };
    
            if (localStorage.getItem('userSubmissionAccepted') !== 'true') {	
                downloadModalButtons.forEach((button) => {
                    button.addEventListener("click", openDownloadModal);
                });
            }

            // Handle click event
            window.addEventListener("click", handleClickOutside);
        
            // Handle touchend event
            window.addEventListener("touchend", handleClickOutside);
        
            downloadModalClose.addEventListener("click", closeDownloadModal);
    
            return () => {
                downloadModalButtons.forEach((button) => {
                    button.removeEventListener("click", openDownloadModal);
                });
        
                window.removeEventListener("click", handleClickOutside);
                window.removeEventListener("touchend", handleClickOutside);
        
                downloadModalClose.removeEventListener("click", closeDownloadModal);
            };
        }
    }, [message]);

    return (
        <div className="subscription-download-form">
            <div className="container" id="subscription-download-form-container">
                <div className="form-wrapper d-flex">
                    <div className="close"><RemoveIcon /></div>
                    <div className="title">Enter your information to get access to the download area.</div>
                    <form action="" className="d-flex" onSubmit={handleSubmit}>
                        <div className="input-block">
                            <label htmlFor="fname">First name</label><br />
                            <input type="text" id="fname" name="first_name" required onChange={handleInputChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="lname">Last name</label><br />
                            <input type="text" id="lname" name="last_name" required onChange={handleInputChange} />
                        </div>
                        <div className="input-block full">
                            <label htmlFor="email">Email</label><br />
                            <input type="email" id="email" name="email" required onChange={handleInputChange} />
                        </div>
                        <div className="terms d-flex">
                            <input type="checkbox" required name="accepted_terms" id="terms" onChange={handleInputChange}/>
                            <label htmlFor="terms"><a href="/terms-of-use">I accept the Terms and Conditions</a></label>
                        </div>

                        <button type="submit" className="gradient-btn">
                            <a><span>Download</span><ArrowRightCircleLightSm /></a>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionDownloadForm;