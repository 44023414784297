import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import ContactService from '../../services/ContactService';
import { types, actions } from './reducer';

function* getContactForm() {
    try {
        const response = yield call(ContactService.getContactForm);
        yield put(actions.getContactFormSucceeded(response.data));
    } catch (error) {
        yield put(actions.getContactFormFailed(extractError(error)));
    }
}

function* submitContactForm({ formData }) {
    try {
        yield call(ContactService.submitContactForm, formData);
        yield put(actions.submitContactFormSucceeded('success'));
    } catch (error) {
        yield put(actions.submitContactFormFailed(extractError(error)));
    }
}

function* watchGetContactForm() {
    yield takeEvery(types.REQUEST_GET_CONTACT_FORM, getContactForm);
}

function* watchSubmitContactForm() {
    yield takeEvery(types.REQUEST_SUBMIT_CONTACT_FORM, submitContactForm);
}

function* contactSaga() {
    yield all([
        fork(watchGetContactForm),
        fork(watchSubmitContactForm),
    ]);
}

export default contactSaga;
