import { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";
import useAnimations from "../../../helpers/animations";

// {faq.keywords?.replace(/,\s*/g, ',').split(',').map((tag) => (
//     <div className="tag">{tag}</div>
// ))}

function Faqs({ data }) {

    useEffect(() => {
        const faqQuestions = document.querySelectorAll('.faq-item .faq-item__question');
    
        const toggleDropdown = (element, contentSelector) => {
            const $element = $(element); // Convert to jQuery object
            $element.toggleClass('opened');
            $element.find(contentSelector).slideToggle(300);
        };
    
        const handleQuestionClick = (event) => {
            const question = event.currentTarget;
            const element = question.closest('.faq-item');
            toggleDropdown(element, '.faq-item__answer');
        };
    
        faqQuestions.forEach(question => {
            question.addEventListener('click', handleQuestionClick);
        });
    
        return () => {
            faqQuestions.forEach(question => {
                question.removeEventListener('click', handleQuestionClick);
            });
        };
    }, []);

    useAnimations({
        usedFor:'entry-banner',
        sections: [
            {
                section: 'section.faqs-body',
                animations: [
                    { target: '.search-sticky-container' },
                    { target: ' .faqs-wrapper .faq-item', stagger: 0.3  },
            
                ]
            },
        ]
    });

    return (
        <div className="faqs-wrapper d-flex">
            {data.map((faq) => (
                <div key={faq.id} className="faq-item">
                    <div className="faq-item__question">
                        <div className="question">
                            {faq.question}
                        </div>
                        <div>
                            <div className="circle">
                                <div className="plus-minus">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq-item__answer">
                        <div className="answer-wrapper">
                            <div className="answer-body">
                                <p>
                                    {ReactHtmlParser(faq.answer)}
                                </p>
                            </div>
                            {/* <div className="answer-footer">
                                <div className="keywords">
                                    <div className="text">Keywords:</div>
                                    <div className="tags-wrapper">
                                        Take the code commented above and paste it here
                                    </div>
                                </div>
                                <button className="gradient-btn">
                                    <Link to={faq.link}>
                                        Learn More
                                    </Link>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="faq-item">
                <div className="faq-item__question">
                    <div className="question">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <div>
                        <div className="circle">
                            <div className="plus-minus">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faq-item__answer">
                    <div className="answer-wrapper">
                        <div className="answer-body">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in odio ut erat sagittis commodo. Pellentesque a velit mi. Aliquam erat volutpat. Vestibulum sagittis sed nulla at convallis. Pellentesque congue
                                tempus porta. Donec efficitur ornare velit, sed tempus massa iaculis id. Aenean lectus arcu, pretium vel suscipit ut, iaculis eu orci. Aliquam quis arcu eu tortor volutpat rhoncus. Curabitur mi nulla, egestas ac
                                diam id, dictum volutpat mi. Nunc urna dolor, tristique vel odio ac, convallis laoreet tellus.
                            </p>
                        </div>
                        <div className="answer-footer">
                            <div className="keywords">
                                <div className="text">Keywords:</div>
                                <div className="tags-wrapper">
                                    <div className="tag">H-Strut channels</div>
                                    <div className="tag">H-112</div>
                                    <div className="tag">H-112-KO</div>
                                    <div className="tag">H-112-OS</div>
                                    <div className="tag">H-112-OS</div>
                                    <div className="tag">H-112-OS2.5</div>
                                </div>
                            </div>
                            <button className="gradient-btn">
                                <Link to="">Learn More</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Faqs;