import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { ArrowRightDarkIcon, SearchDarkIcon, CloseMobileIcon } from '../../assets/icons';
import SearchResults from '../../components/SearchResults';
import useAnimations from '../../helpers/animations';
import { actions } from "../../redux/catalog/reducer";

function Products({ data, solutions }) {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState(undefined);

    const productList = useSelector((state) => state.catalog.productListSearch);

    useEffect(() => {
        if (searchValue) {
            dispatch(actions.requestGetProductListSearch(searchValue));
        }
    }, [searchValue]);

    useEffect(() => {
        const updateTitlePositions = () => {
            var titleElements = document.querySelectorAll('section.products .single-category-wrapper .category .title');
        
            titleElements.forEach(function (element) {
                var calculatedTop = `calc(100% - ${element.clientHeight}px)`;
                element.style.top = calculatedTop;
            });
        };

        updateTitlePositions();

        // Add event listener for window resize
        window.addEventListener('resize', function () {
            // Update title positions when window is resized
            updateTitlePositions();
        });

    }, []);

    useAnimations({
        sections: [
            {
                section: 'section.products',
                animations: [
                    { target: 'h1' },
                    { target: '.categories-wrapper .single-category-wrapper', stagger: 0.2 }
                ]
            },
        ]
    });

    document.getElementById('searchForm')?.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    });

    return (
        <section className="products hp bg-light">
            <div className="container">
                <div className="search-block d-flex">
                    <div className="search-container">
                        <form
                            action=""
                            id="searchForm"
                        >
                            <input
                                type="text"
                                placeholder={data?.search_bar.title}
                                name="search"
                                className="searchInput"
                                value={searchValue}
                                onChange={(e) => {setSearchValue(e.target.value)}}
                            />
                            {searchValue ?
                                <button
                                    type="button"
                                    className="clear"
                                    onClick={() => {
                                        setSearchValue('');
                                    }}
                                >
                                    <CloseMobileIcon />
                                </button>
                                :
                                <button disabled type="submit" className="submit">
                                    <SearchDarkIcon />
                                </button>
                            }
                        </form>
                        <SearchResults data={productList} />
                    </div>
                    <div className="tags-wrapper d-flex">
                        <div className="label">Suggested tags:</div>
                        {ReactHtmlParser(data?.search_bar.description)}
                    </div>
                </div>
                <h1 className="font-dark">
                    {ReactHtmlParser(data?.solutions_header.description)}
                </h1>
                <div className="categories-wrapper d-flex">
                    {solutions?.slice(0, 4).map((solution) => (
                        <div key={solution.id} className="single-category-wrapper">
                            <div className="category">
                                <div className="image">
                                    <img src={solution.img_url} alt={solution.title.split(' ').join('-')} />
                                </div>
                                <div className="title">
                                    <h5 className="font-light">
                                        {ReactHtmlParser(solution.title)}
                                    </h5>
                                </div>
                                <div className="bottom">
                                    <div className="divider"></div>
                                    <div className="text">
                                        {solution.description}
                                    </div>
                                    <button className="outline-btn"><a href={solution.button_link_2}>{solution.button_name}</a></button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="view-all d-flex">
                    <div className="border"></div>
                    <a href={data?.solutions_footer.button_link} className="d-flex"> <span>{data?.solutions_footer.button_name}</span>
                        <ArrowRightDarkIcon />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Products;
