import BaseService from './BaseService';

class CatalogService extends BaseService {
    getCategories = () => this.get('/categories/?format=json');

    getProductList = (filters) => this.post(`/products-by-category/?format=json`, filters || { });
    
    getProductListSearch = (filters) => this.get(`/search-products-by-name/?search=${filters}&format=json`, filters || { });
    
    viewProduct = (data) => this.post('/get-product/?format=json', data);
}

export default new CatalogService();
