const model = {
    loading: false,
    error: null,
    news: [],
    singleNews: null,
    listOfYears: [],
    tags: [],
};

export default model;
