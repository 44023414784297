import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import $ from 'jquery';
import {
    InfoCircleLgIcon,
} from "../../../assets/icons";
import StickySearch from "../../../components/StickySearch";
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import { actions } from "../../../redux/cross_reference/reducer";
import useAnimations from "../../../helpers/animations";
import { updateTitle } from "../../../helpers/utils";
import CrossReffBanner from "./CrossRefBanner";
import ProductList from "./ProductList";

function CrossReference() {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchValue = queryParams.get('search');

    const [dataLoaded, setDataLoaded] = useState(false);
    const crossReferencePage = useSelector(state => state.staticData.crossReferencePage);
    const data = useSelector(state => state.crossReference.data);

    useEffect(() => {
        if (searchValue) {
            dispatch(actions.requestGetCrossReferenceProductList({ search: searchValue }));
        }
    }, [dispatch, searchValue]);

    useEffect(() => {
        if (data.length > 0) {
            setDataLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        const toggleDropdown = (element, contentSelector) => {
            const $element = $(element);
            $element.toggleClass('opened');
            $element.find(contentSelector).slideToggle(300);
        };
    
        const handleHeadClick = (event) => {
            const head = event.currentTarget;
            const element = $(head).closest('.search-tips-block');
            toggleDropdown(element, '.content');
        };
    
        $(document).on('click', '.cross-reference-body .container .search-tips-block .head', handleHeadClick);
    
        return () => {
            $(document).off('click', '.cross-reference-body .container .search-tips-block .head', handleHeadClick);
        };
    }, []);

    const crossReferenceSearchTipsDesc = crossReferencePage?.section_2.cross_reference_search_tips.description;
    const startIndex = crossReferenceSearchTipsDesc?.indexOf('<p>');
    const endIndex = crossReferenceSearchTipsDesc?.indexOf('</p>') + 4;
    const paragraph = crossReferenceSearchTipsDesc?.slice(startIndex, endIndex);

    useAnimations({
        sections: [
            {
                section: 'section.cross-reference-body',
                animations: [
                    { target: '.search-sticky-container' },
                    { target: ' .container .search-tips-block' },
                ]
            }
        ]
    });

    useEffect(() => {
        updateTitle('Haydon - Product Cross Reference');
    }, []);

    return (
        <div className="cross-reference-page">
            <HeaderFixed activeTab="resources" />
            <CrossReffBanner data={crossReferencePage?.section_1} />
            <section className="cross-reference-body bg-grey search-fixed-surrounding-body">
                <StickySearch usedFor="cross-ref" />
                <div className="container">
                    <div className="search-tips-block d-flex">
                        <div className="head d-flex">
                            <div className="left d-flex">
                                <InfoCircleLgIcon />
                                <h3>{crossReferencePage?.section_2.cross_reference_search_tips.title}</h3>
                            </div>
                            <div>
                                <div className="circle">
                                    <div className="plus-minus">
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            {ReactHtmlParser(paragraph)}
                            <div className="bottom">
                                {ReactHtmlParser(crossReferenceSearchTipsDesc?.slice(endIndex + 4))}
                            </div>
                        </div>
                    </div>
                    {dataLoaded && <ProductList data={data} />}
                </div>
            </section>
        </div>
    );
};

export default CrossReference;
