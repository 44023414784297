import { SquareIcon } from "../../assets/illustrations";
import useAnimations from "../../helpers/animations";

function Resources({ data }) {
    useAnimations({
        sections: [
            {
                section: 'section.resources',
                animations: [
                    { target: 'h1' },
                    { target: '.text' },
                    { target: '.resources-wrapper .single-resource-wrapper', stagger: 0.2 }
                ]
            },
        ]
    });

    return (
        <section className="resources hp bg-dark">
            <div className="container">
                <h1 className="font-light">{data?.resources_header.title}</h1>
                <div className="text">
                    {data?.resources_header.description}
                </div>
                <div className="resources-wrapper d-flex">
                    {data?.resources?.slice(0, 5).map((resource) => (
                        <div key={resource.id} className="single-resource-wrapper">
                            <div className="resource">
                                <a href={resource.button_link}>
                                    <SquareIcon />
                                    <div className="upper d-flex">
                                        <div className="illustration">
                                            <img src={resource.img_url} alt={resource.title} />
                                        </div>
                                        <h4>{resource.title}</h4>
                                        <div className="text">
                                            {resource.description}
                                        </div>
                                    </div>
                                    <div className="btn">
                                        <span>{resource.button_name}</span>
                                        <img src={resource.button_icon} alt="arrow-right-light" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="cta d-flex">
                    <div className="left">{data?.resources_footer.title}</div>
                    <button className="outline-btn">
                        <a href="/contact" className="d-flex">
                            <span>{data?.resources_footer.button_name}</span>
                            <img src={data?.resources_footer.button_icon} alt="arrow-circle-right-light" />
                        </a>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Resources;
