import { ArrowRightCircleDarkIcon, ArrowRightCircleLightSm, PdfLightSmIcon } from '../../../assets/icons';

function Literature({ data, setLinkToDownload }) {
    return (
        data?.map((item) => (
            <section className={`data ${item.name === "H-Strut" || item.name === "Roof Blocks" ? 'bg-grey' : 'bg-dark'} nav-tab --${item.id}`} id={item.name.split(' ').join('-')} key={item.id}>
                <div className="container">
                    {item.name === "H-Strut"
                        && (
                            <>
                                <h3 className="font-dark">H-Strut Catalogs / <span>Brochures</span></h3>
                                <p>Now you can browse our latest products easily right from your computer, tablet or phone. Just click on the icons or links below and download the latest copy of everything you need.</p>
                            </>
                        )}
                    {item.name === 'Baseboard'
                        && (
                            <>
                                <h3 className="font-light">
                                    Baseboard <span>Heating </span> <br />
                                    Catalogs / Brochures
                                </h3>
                            </>
                        )}
                    {item.name === 'Roof Blocks'
                        && (
                            <h3 className="font-dark">H-Block Rooftop Supports Catalogs / <span>Brochures</span></h3>
                        )}
                    <div className="data-wrapper d-flex">
                        {item.files.map((file) => (
                            <div className="single-data-wrapper literature-data" key={file.id}>
                                <div className="single-data">
                                    <div className="icon download-form">
                                        <div><PdfLightSmIcon /></div>
                                    </div>
                                    <div className="bg-image">
                                        <img src={file.image} alt="document-cover" />
                                    </div>
                                    <div className="title-wrapper">
                                        <div className="title">{file.name}</div>
                                    </div>
                                    <button className="download-form" onClick={() => {
                                        setLinkToDownload(file.pdf)
                                        if (localStorage.getItem('userSubmissionAccepted') === 'true') {
                                            window.open(file.pdf, '_blank')
                                        }
                                    }}>
                                        <a>View Document</a>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="btn-wrapper d-flex">
                        <button className="outline-btn">
                            <a href="/catalog">
                                <span>View {item.name} Products</span>
                                {item.name === "H-Strut"
                                    ? <ArrowRightCircleDarkIcon />
                                    : <ArrowRightCircleLightSm />}
                            </a>
                        </button>
                        {/* <button className="gradient-btn">
                            <Link to="">
                                <span>Request a Quote</span>
                                <ArrowRightCircleLightSm />
                            </Link>
                        </button> */}
                    </div>
                </div>
            </section>
        ))
    )
}

export default Literature;
