import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { AnimatedLogoDarkIcon } from "../../assets";
import { UserLightSmIcon } from "../../assets/icons"
import SearchModalButton from "../MainLayout/SearchModalButton";
// import Cart from "../../components/Cart";

function Banner({
    banner = "home-page-banner",
    backgroundBanner,
    children,
    activeTab,
}) {

    const menu = useSelector((state) => state.menu.headerMenu);
    const catalogMenu = useSelector((state) => state.menu.catalogMenu);
    const productsBySectorMenu = useSelector((state) => state.menu.productsBySectorMenu);
    const catalogModal = useSelector((state) => state.staticData.catalogModal);
    const productsBySectorModal = useSelector((state) => state.staticData.productsBySectorModal);

    // Reference to the default active link header
    const defaultActiveLinkHeader2 = $('.banner-header .header-l.active');

    useEffect(() => {
        function handleMegaMenuClick(e) {
            e.stopPropagation();

            const menuId = e.currentTarget.id;
            // Check if the clicked menu item has a corresponding mega menu
            if ($('#mega-menu-' + menuId + '-2').length) {
                const megaMenuId = 'mega-menu-' + menuId + '-2';
                // Check if the mega menu is already active
                if ($('.banner-header #' + megaMenuId).hasClass('active')) {
                    // // Remove the active class from the mega menu
                    $('.banner-header .mega-menu').removeClass('active');
                    $('.banner-header ').removeClass('menu-active');
                    $(this).removeClass('mega-menu-active');
                    defaultActiveLinkHeader2.addClass('active');
                } else {
                    // Mega menu is not active, proceed with opening it
                    $('.banner-header .mega-menu').removeClass('active');
                    $('.banner-header #' + megaMenuId).addClass('active');
                    $('.banner-header ').addClass('menu-active');
                    $(this).addClass('mega-menu-active');
                    $('.banner-header .header-l').not(this).removeClass('active');
                    $('.banner-header .header-l').not(this).removeClass('mega-menu-active');
                }
            }
        };
    
        // Add click event listener to all menu items containing mega menus
        $('.banner-header .menu-l').on('click', handleMegaMenuClick);
    
        // Cleanup event listener when the component unmounts
        return () => {
            $('.banner-header .menu-l').off('click', handleMegaMenuClick);
        };
    }, [menu]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (!$(event.target).closest('.header').length) {
                $('.mega-menu').removeClass('active');
                $('.menu-l').removeClass('mega-menu-active');
                defaultActiveLinkHeader2.addClass('active');
            }
        };
    
        $(document).on('click', handleDocumentClick);
    
        // Cleanup function
        return () => {
            $(document).off('click', handleDocumentClick);
        };
    }, []);

    return (
        <section className={`entry-banner ${activeTab === "home" && 'hp'}`}>
            {banner !== "home-page-banner" && (
                <div className="background">
                    <img src={backgroundBanner} alt="cover-image" />
                </div>
            )}
            <div className="header banner-header">
                <div className="upper">
                    <div className="container">
                        {/* <Link to="" className="user-access">
                            <UserLightSmIcon />
                            <span>Login</span>
                        </Link> */}
                        <SearchModalButton />
                    </div>
                </div>
                <div className="main-header">
                    <div className="container">
                        <div className="logo">
                            <a href="/"><AnimatedLogoDarkIcon /></a>
                        </div>
                        <div className="mobile-menu-button">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className="right d-flex">
                            <ul>
                                {menu.map((item) => {
                                    if (item.menu_link) {
                                        return (
                                            <li key={item.id} className={`header-l ${activeTab === item.key && 'active'}`}>
                                                <a href={item.menu_link}>{item.menu_name}</a>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li
                                                key={item.id}
                                                className={`menu-l header-l ${activeTab === item.key && 'active'}`}
                                                id={item.key === 'products-by-sector' ? 'industry' : item.key}
                                            >
                                                <a>{item.menu_name}</a>
                                                {item.children?.length > 0 && (
                                                    <div className="mega-menu sm" id={`mega-menu-${item.key}-2`}>
                                                        <ul>
                                                            {item.children.map((child, i) => {
                                                                return (
                                                                    child.key !== 'request-a-quote' &&
                                                                    <li key={i}>
                                                                        <Link to={child.menu_link}>{child.menu_name}</Link>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                            {/* <div className="divider"></div> */}
                            {/* <Cart usedFor="banner" /> */}
                        </div>
                    </div>
                </div>
                <div className="mega-menu lg" id="mega-menu-catalog-2">
                    <div className="container">
                        <div className="mega-menu-wrapper d-flex">
                            <div className="image d-flex">
                                <img src={catalogModal?.section_1.catalog_header.img_url} alt="metal-framing" />
                                <div className="text">
                                    <h2>
                                        {catalogModal?.section_1.catalog_header.title}
                                    </h2>
                                    <p>
                                        {catalogModal?.section_1.catalog_header.description}
                                    </p>
                                    <button><a href="/catalog/category">{catalogModal?.section_1.catalog_header.button_name}</a></button>
                                </div>
                            </div>
                            <div className="content d-flex">
                                {catalogMenu.map((item) => (
                                    <div className="col" key={item.category_id}>
                                        <div className="title"><a href={item.menu_link}>{item.label_name}</a></div>
                                        <ul>
                                            {item.children?.map((child) => (
                                                <li key={child.category_id}>
                                                    <a href={child.menu_link}>{child.label_name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mega-menu lg" id="mega-menu-industry-2">
                    <div className="container">
                        <div className="mega-menu-wrapper d-flex">
                            <div className="image d-flex">
                                <img
                                    src={productsBySectorModal?.section_1.products_by_sector_header.img_url}
                                    alt="metal-framing"
                                />
                                <div className="text">
                                    <h2>{productsBySectorModal?.section_1.products_by_sector_header.title}</h2>
                                    <p>{productsBySectorModal?.section_1.products_by_sector_header.description}</p>
                                    <button>
                                        <a href="/our-solutions">
                                            {productsBySectorModal?.section_1.products_by_sector_header.button_name}
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="content d-flex">
                                {productsBySectorMenu.map((item) => (
                                    <div className="col" key={item.category_id}>
                                        <div className="title">
                                            <a href={item.menu_link}>{item.label_name}</a>
                                        </div>
                                        <ul>
                                            {item.children?.map((child, i) => (
                                                <li key={`${child.category_id}-${i}`}>
                                                    <a href={child.menu_link}>{child.label_name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {children}
        </section>
    );
}

export default Banner;