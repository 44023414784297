import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import HeaderFixed from '../../../components/MainLayout/HeaderFixed';
import StickySearch from '../../../components/StickySearch';
import StickyNavigation from '../../../components/StickyNavigation';
import SubscriptionDownloadForm from '../../../components/SubscriptionDownloadForm';
import { actions } from '../../../redux/dynamic_data/reducer';
import { updateTitle } from '../../../helpers/utils';
import LiteratureBanner from './LiteratureBanner';
import Literature from './Literature';

function LiteraturePage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchValue = queryParams.get('search');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [linkToDownload, setLinkToDownload] = useState('');

    const literaturePage = useSelector(state => state.staticData.literaturePage);
    const data = useSelector(state => state.dynamicData.literature);

    useEffect(() => {
        dispatch(actions.requestGetLiterature({ search: searchValue?.split('_').join(' ')}));
    }, [dispatch, searchValue]);

    useEffect(() => {
        if (data.length > 0) {
            setDataLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        updateTitle('Haydon - Literature');
    }, []);

    return (
        <div className="literature-page">
            <HeaderFixed activeTab="resources" />
            {dataLoaded && <SubscriptionDownloadForm linkToDownload={linkToDownload} />}
            <LiteratureBanner data={literaturePage?.section_1} />
            <section className="technical-body bg-grey search-fixed-surrounding-body">
                <StickySearch usedFor="literature" />
                <StickyNavigation
                    bodyClass=".technical-body"
                    usedFor="entry-banner"
                    sectionElement="section.technical-body"
                    targetElements={[
                        { target: '.search-sticky-container' },
                        { target: '.navigation-sticky-container' },
                    ]}
                >
                    {data?.map((item) => (
                        <a href={`#${item.name.split(' ').join('-')}`} key={item.id} className={`single-navigation ${item.id === 0 ? 'active' : ''}`}>{item.name}</a>
                    ))}
                </StickyNavigation>
                {dataLoaded && <Literature data={data} setLinkToDownload={setLinkToDownload} />}
            </section>
        </div>
    );
};

export default LiteraturePage;
