import { useEffect } from "react";
import { ChevronLeftDarkIcon } from "../assets/icons";
import { NotFound404Icon } from "../assets/illustrations";
import HeaderFixed from "../components/MainLayout/HeaderFixed";
import { updateTitle } from "../helpers/utils";

function NotFound() {
    useEffect(() => {
        updateTitle('Page Not Found');
    }, []);

    return (
        <div className="not-found-page">
            <HeaderFixed isNotFoundPage={true} />
            <section className="not-found-body bg-light">
                <div className="container">
                    <div className="illustration">
                        <NotFound404Icon />
                    </div>
                    <h2 className="font-dark">Page Not Found</h2>
                    <button className="outline-btn">
                        <a href="/" >
                            <ChevronLeftDarkIcon />
                            <span>Back to homepage</span>
                        </a>
                    </button>
                </div>
            </section>
        </div>
    );
}

export default NotFound;
