import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderFixed from "../../components/MainLayout/HeaderFixed";
import { updateTitle } from "../../helpers/utils";
import Content from "./Content";
import Loading from "../HomePage/Loading";

function TermsOfService() {
    const [dataLoaded, setDataLoaded] = useState(false);
    const data = useSelector(state => state.staticData.termsAndConditionsPage);

    useEffect(() => {
        if (data) {
            setDataLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        updateTitle('Haydon - Terms of Use');
    }, []);

    return (
        !dataLoaded ? (
            <Loading />
        ) : (
            <div className="terms-of-service-page">
                <HeaderFixed isPrivacyPage={true} />
                <Content data={data} />
            </div>
        )
    );
};

export default TermsOfService;
