import Banner from "../../../components/Banner";

function CertificationsBanner({ data }) {
    return (
        <Banner
            banner="certifications-banner"
            backgroundBanner={data?.certifications_header.img_url}
            activeTab="resources"
        >
            <div className="container">
                <div className="text-content d-flex">
                    <h1 className="font-light">{data?.certifications_header.title}</h1>
                    <div className="text">
                        {data?.certifications_header.description}
                    </div>
                </div>
            </div>
        </Banner>
    );
};

export default CertificationsBanner;
