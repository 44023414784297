import { useEffect } from "react";
import useAnimations from "../../helpers/animations";

function StickyNavigation ({
    children,
    bodyClass,
    usedFor,
    sectionElement,
    targetElements,
}) {
    useEffect(() => {
        if (document.querySelector(".navigation-wrapper")) {
            // Function to update active navigation tab
            function updateActiveNav() {
                let scrollPosition = window.scrollY;
                document.querySelectorAll('.nav-tab').forEach(function (tab) {
                    let sectionId = tab.id;
                    let sectionOffset = tab.offsetTop - 300;
                    if (scrollPosition >= sectionOffset) {
                        document.querySelectorAll('.single-navigation').forEach(function (nav) {
                            nav.classList.remove('active');
                        });
                        document.querySelector('a[href="#' + sectionId + '"]')?.classList.add('active');
                    }
                });
            }
    
            // Initial call to set active tab on page load
            updateActiveNav();
        
            // Update active tab on scroll
            window.addEventListener('scroll', updateActiveNav);
        
            return () => {
                // Cleanup: Remove the scroll event listener when the component is unmounted
                window.removeEventListener('scroll', updateActiveNav);
            };
        }
    }, []);

    // Sticky navigation class toggle
    useEffect(() => {
        const handleNavActive = () => {
            let nav = document.querySelector(".navigation-sticky-container");
            let wrapper = document.querySelector(bodyClass);
            let wrapperRect = wrapper.getBoundingClientRect();
            let isScrolledPast = wrapperRect.top <= 0;

            if (isScrolledPast) {
                nav.classList.add("is-sticky");

            } else if (!isScrolledPast) {
                nav.classList.remove("is-sticky");

            }
        }

        window.addEventListener('scroll', handleNavActive);

        return () => {
            window.removeEventListener('scroll', handleNavActive);
        };
    }, []);

    useAnimations(({
        usedFor,
        sections: [
            {
                section: sectionElement,
                animations: targetElements,
            }
        ]
    }));

    return (
        <div className="navigation-sticky-container">
            <div className="navigation-wrapper">
                <div className="navigation d-flex">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StickyNavigation;
