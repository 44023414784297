import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeaderFixed from '../../components/MainLayout/HeaderFixed';
import { updateTitle } from '../../helpers/utils';
import Form from './Form';
import Info from './Info';
import Loading from '../HomePage/Loading';

function Contact() {
    const [dataLoaded, setDataLoaded] = useState(false);
    const contactPage = useSelector((state) => state.staticData.contactPage);

    useEffect(() => {
        if (contactPage) {
            setDataLoaded(true);
        }
    }, [contactPage]);

    useEffect(() => {
        updateTitle('Haydon - Contact');
    }, []);

    return (
        !dataLoaded ? (
            <Loading />
        ) : (
            <div className="contact-page">
                <HeaderFixed activeTab="contact" />
                <Form data={contactPage?.section_1} />
                <Info data={contactPage?.section_3} />
            </div>
        )
    );
};

export default Contact;