import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SearchDarkIcon, CloseMobileIcon } from "../../assets/icons";
import { actions as literatureActions } from "../../redux/dynamic_data/reducer";
import { actions as techDataActions } from "../../redux/dynamic_data/reducer";
import { actions as faqActions } from "../../redux/dynamic_data/reducer";
import { actions } from "../../redux/cross_reference/reducer";

function StickySearch({ usedFor }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [searchValue, setSearchValue] = useState(queryParams.get('search') || '');

    const literatureSuggestions = useSelector((state) => state.dynamicData.literatureSuggestions);
    const technicalDataSuggestions = useSelector((state) => state.dynamicData.techDataSuggestions);
    const crossReferenceSuggestions = useSelector((state) => state.crossReference.suggestions);
    const faqSuggestions = useSelector((state) => state.dynamicData.faqSuggestions);

    // handle input change
    const handleInputChange = (value) => {
        setSearchValue(value);
        if (usedFor === 'literature') {
            dispatch(literatureActions.requestGetLiteratureSuggestions(value));
        }
        if (usedFor === 'technical-data') {
            dispatch(techDataActions.requestGetTechnicalDataSuggestions(value));
        }
        if (usedFor === 'cross-ref') {
            dispatch(actions.requestGetCrossReferenceProductSuggestions(value));
        }
        if (usedFor === 'faq') {
            dispatch(faqActions.requestGetFaqSuggestions(value));
        }
    };

    // sticky search bar
    const [isSearchFixed, setIsSearchFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const search = document.querySelector('.search-sticky-container');
            const observer = document.querySelector('.search-fixed-surrounding-body');
            const observerRect = observer.getBoundingClientRect();
            const isScrolledPast = observerRect.top <= 0;

            if (isScrolledPast && !isSearchFixed) {
                search.classList.add('is-fixed');
                setIsSearchFixed(true);
            } else if (!isScrolledPast && isSearchFixed) {
                search.classList.remove('is-fixed');
                setIsSearchFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isSearchFixed]);

    document.getElementById('stickySearch')?.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    });

    return (
        <div className="search-sticky-container">
            <div className="search-block d-flex fixed">
                {usedFor === 'cross-ref' && <h3 className="font-light">Cross Reference Search</h3>}
                <div className="search-container">
                    <form
                        action=""
                        id="stickySearch"
                    >
                        <input
                            type="text"
                            placeholder="Search..."
                            name="search"
                            className="searchInput"
                            value={searchValue}
                            onChange={(e) => {handleInputChange(e.target.value)}}
                        />
                        {searchValue !== '' ?
                            <button
                                type="button"
                                className="clear"
                                onClick={() => {
                                    setSearchValue('');
                                }}
                            >
                                <CloseMobileIcon />
                            </button>
                            :
                            <button disabled type="submit" className="submit">
                                <SearchDarkIcon />
                            </button>
                        }
                    </form>
                    <div className="search-results d-flex">
                        {usedFor === 'literature' && literatureSuggestions?.length > 0 && (
                            literatureSuggestions.map((suggestion) => (
                                <div className="suggestion">
                                    <div
                                        key={suggestion.id}
                                        className="result"
                                    >
                                        <a href={`/literature?search=${suggestion.name.toLowerCase()}`}>{suggestion.name.split('_').join(' ')}</a>
                                    </div>
                                </div>
                            ))
                        )}
                        {usedFor === 'technical-data' && technicalDataSuggestions?.length > 0 && (
                            technicalDataSuggestions.map((suggestion) => (
                                <div className="suggestion">
                                    <div
                                        key={suggestion.id}
                                        className="result"
                                    >
                                        <a href={`/technical-data?search=${suggestion.name.toLowerCase()}`}>{suggestion.name.split('_').join(' ')}</a>
                                    </div>
                                </div>
                            ))
                        )}
                        {usedFor === 'cross-ref' && crossReferenceSuggestions?.length > 0 && (
                            crossReferenceSuggestions.map((suggestion) => (
                                <div className="suggestion">
                                    <div
                                        key={suggestion.id}
                                        className="result"
                                    >
                                        <a href={`/product-cross-reference?search=${suggestion.haydon_part}`}>{suggestion.product_name}</a>
                                    </div>
                                </div>
                            ))
                        )}
                        {usedFor === 'faq' && faqSuggestions?.length > 0 && (
                            faqSuggestions.map((suggestion) => (
                                <div className="suggestion">
                                    <div
                                        key={suggestion.id}
                                        className="result"
                                    >
                                        <a href={`/faq?search=${suggestion.question.split(' ').join('-')}&id=${suggestion.id}`}>{suggestion.question}</a>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StickySearch;
