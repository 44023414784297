import axios from 'axios';

class BaseService {
    constructor(baseUrl = process.env.REACT_APP_API_URL) {
        // this.token = StorageManager.get('token');

        this.api = axios.create({
            baseURL: baseUrl,
            headers: { 'Content-type': 'application/json' },
        });

        this.authInterceptor = this.api.interceptors.request.use((config) => {
            // const token = StorageManager.get('token');
            return {
                ...config,
                headers: {
                    ...config.headers,
                    // Authorization: `Bearer ${token}`,
                },
            };
        });

        this.responseInterceptors = this.api.interceptors.response.use(
            (response) => response.data,
            (error) => {
                // if (error.response && error.response.status === 401) {
                //     removeToken();
                //     removeRefreshToken();
                //     window.location.reload();
                // }
                return Promise.reject(error);
            },
        );
    }

    get = (url, options) => this.api.get(url, options);

    post = (url, data, options) => this.api.post(url, data, options);

    // put = (url, data, options) => this.api.put(url, data, options);

    // patch = (url, data, options) => this.api.patch(url, data, options);

    // delete = (url, options) => this.api.delete(url, options);
}

export default BaseService;
