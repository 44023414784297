import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get cross reference product list
    requestGetCrossReferenceProductList: ['filters'],
    getCrossReferenceProductListSucceeded: ['data'],
    getCrossReferenceProductListFailed: ['error'],

    // get cross reference product suggestions
    requestGetCrossReferenceProductSuggestions: ['filters'],
    getCrossReferenceProductSuggestionsSucceeded: ['data'],
    getCrossReferenceProductSuggestionsFailed: ['error'],
});

export default createReducer(initialState, {
    // get cross reference product list
    [types.REQUEST_GET_CROSS_REFERENCE_PRODUCT_LIST]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_CROSS_REFERENCE_PRODUCT_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        data,
        loading: false,
    }),
    [types.GET_CROSS_REFERENCE_PRODUCT_LIST_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get cross reference product suggestions
    [types.REQUEST_GET_CROSS_REFERENCE_PRODUCT_SUGGESTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_CROSS_REFERENCE_PRODUCT_SUGGESTIONS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        suggestions: data,
        loading: false,
    }),
    [types.GET_CROSS_REFERENCE_PRODUCT_SUGGESTIONS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),
});

export { types, actions };
