import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../screens/HomePage';
import Catalog from '../screens/Catalog';
import About from '../screens/About';
import Contact from '../screens/Contact';
import MainLayout from '../components/MainLayout';
import NotFound from '../screens/NotFound';
import OurSolutions from '../screens/OurSolutions';
import Literature from '../screens/Resources/Literature';
import TechnicalData from '../screens/Resources/TechnicalData';
import Faq from '../screens/Resources/Faq';
import SingleProduct from '../screens/Catalog/SingleProduct';
import News from '../screens/Resources/News';
import CrossReference from '../screens/Resources/CrossReference';
import NewsSingle from '../screens/Resources/News/NewsSingle';
import Certifications from '../screens/Resources/Certifications';
import Privacy from '../screens/Privacy';
import TermsOfService from '../screens/TermsOfService';
// import RedirectCategory from '../screens/RedirectCategory';

function AppRouter() {
    return (
        <Router>
            <MainLayout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/catalog/category/:categoryName" element={<RedirectCategory />} /> */}
                    <Route path="/catalog/category/*" element={<Catalog />} />
                    <Route path="/catalog/product/:productName" element={<SingleProduct />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/literature" element={<Literature />} />
                    <Route path="/technical-data" element={<TechnicalData />} />
                    <Route path="/product-cross-reference" element={<CrossReference />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/news-single" element={<NewsSingle />} />
                    <Route path="/certifications" element={<Certifications />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/our-solutions" element={<OurSolutions />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="terms-of-use" element={<TermsOfService />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </MainLayout>
        </Router>
    );
};

export default AppRouter;
