import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get static data
    requestGetStaticData: [],
    getStaticDataSucceeded: [
        'homePage',
        'mainPage',
        'aboutPage',
        'solutionsPage',
        'faqsPage',
        'technicalDataPage',
        'contactPage',
        'catalogModal',
        'productsBySectorModal',
        'catalogPage',
        'privacyPage',
        'termsAndConditionsPage',
        'literaturePage',
        'crossReferencePage',
        'certificationsPage',
        'newsPage',
    ],
    getStaticDataFailed: ['error'],
});

export default createReducer(initialState, {
    // get static data
    [types.REQUEST_GET_STATIC_DATA]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_STATIC_DATA_SUCCEEDED]: (state, {
        homePage,
        mainPage,
        aboutPage,
        solutionsPage,
        faqsPage,
        technicalDataPage,
        contactPage,
        catalogModal,
        productsBySectorModal,
        catalogPage,
        privacyPage,
        termsAndConditionsPage,
        literaturePage,
        crossReferencePage,
        certificationsPage,
        newsPage,

    }) => ({
        ...state,
        homePage,
        mainPage,
        aboutPage,
        solutionsPage,
        faqsPage,
        technicalDataPage,
        contactPage,
        catalogModal,
        productsBySectorModal,
        catalogPage,
        privacyPage,
        termsAndConditionsPage,
        literaturePage,
        crossReferencePage,
        certificationsPage,
        newsPage,
        loading: false,
    }),
    [types.GET_STATIC_DATA_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),
});

export { types, actions };
