import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get menu 
    requestGetMenu: [],
    getMenuSucceeded: ['headerMenu', 'footerMenu'],
    getMenuFailed: ['error'],

    // get menu categories
    requestGetMenuCategories: [],
    getMenuCategoriesSucceeded: ['catalogMenu', 'productsBySectorMenu'],
    getMenuCategoriesFailed: ['error'],
});

export default createReducer(initialState, {
    // get menu 
    [types.REQUEST_GET_MENU]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_MENU_SUCCEEDED]: (state, { headerMenu, footerMenu }) => ({
        ...state,
        headerMenu,
        footerMenu,
        loading: false,
    }),
    [types.GET_MENU_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get menu categories
    [types.REQUEST_GET_MENU_CATEGORIES]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_MENU_CATEGORIES_SUCCEEDED]: (state, {
        catalogMenu,
        productsBySectorMenu,
    }) => ({
        ...state,
        catalogMenu,
        productsBySectorMenu,
        loading: false,
    }),
    [types.GET_MENU_CATEGORIES_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),
});

export { types, actions };