import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import Swiper from 'swiper/bundle';
import { ArrowLeftLightMdIcon, ArrowRightLightMdIcon, PlayVideoIcon } from "../../assets/icons";    
import useAnimations from "../../helpers/animations";

function News({ data, news }) {
    const [homePageNews, setHomePageNews] = useState([]);

    useEffect(() => {
        if(news.length > 6) {
            setHomePageNews(news.slice(0, 6));
        } else {
            setHomePageNews(news);
        }
    }, [news]);

    useEffect(() => {
        const swiperNews = new Swiper('.swiper-news', {
            speed: 500,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1.3,
                    spaceBetween: 20,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 25,
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                },

                992: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 3.5,
                    spaceBetween: 40,
                },
                // when window width is >= 480px
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 47,
                },
                // when window width is >= 640px
                1600: {
                    slidesPerView: 4.5,
                    spaceBetween: 47,
                }
            }
        });
    }, []);

    useAnimations({
        sections: [
            {
                section: 'section.news-slider',
                animations: [
                    { target: '.container .text-content' }
                ]
            }
        ]
    });

    return (
        <section className="news-slider hp bg-dark">
            <div className="container">
                <div className="text-content">
                    <h1 className="font-light">
                        {/* Stay in
                        the <span>know</span> */}
                        {ReactHtmlParser(data?.news_header.title)}
                    </h1>
                    <div className="text">
                        {/* Discover how our spark is shaping the future! Get updates on the latest news, product innovations,
                        events and featured programs from Haydon. */}
                        {data?.news_header.description}
                    </div>
                </div>
                <div className="swiper swiper-news">
                    <div className="swiper-wrapper">
                        {homePageNews?.map((newsItem) => (
                            <div key={newsItem.id} className="swiper-slide">
                                <div className="bg-image">
                                    <img src={newsItem.cover_media} alt="news-cover" />
                                </div>
                                <div className="inner-content d-flex">
                                    <div className="upper">
                                        {newsItem.is_video ? (
                                            <div className="play-video">
                                                <a
                                                    href={`/news-single?${newsItem.title.split(' ').join('-').toLowerCase()}&newsId=${newsItem.id}`}
                                                    className="d-flex"
                                                >
                                                    <PlayVideoIcon />
                                                    <span>Watch Video</span>
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="category">{newsItem.tags[0]}</div>
                                        )}
                                    </div>
                                    <div className="bottom d-flex">
                                        <div className="date">{newsItem.publish_date}</div>
                                        <div className="divider"></div>
                                        <p>{newsItem.title}</p>
                                        <button
                                            className="outline-btn style-2"
                                        >
                                            <a href={`/news-single?${newsItem.title.split(' ').join('-').toLowerCase()}&newsId=${newsItem.id}`}>
                                                Read more
                                            </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="swiper-button-prev">
                        <ArrowLeftLightMdIcon className="news-slider-arrow" />
                    </div>
                    <div className="swiper-button-next">
                        <ArrowRightLightMdIcon className="news-slider-arrow"/>
                    </div>
                </div>
                <div className="view-all d-flex">
                    <div className="border"></div>
                    <a href={data?.news_footer.button_link} className="d-flex">
                        <span>{data?.news_footer.button_name}</span>
                        <ArrowRightLightMdIcon />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default News;
