import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeaderFixed from "../../components/MainLayout/HeaderFixed";
import { updateTitle } from "../../helpers/utils";
import Loading from "../HomePage/Loading";
import Content from "./Content";

function Privacy() {
    const [dataLoaded, setDataLoaded] = useState(false);
    const data = useSelector(state => state.staticData.privacyPage);

    useEffect(() => {
        if (data) {
            setDataLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        updateTitle('Haydon - Privacy');
    }, []);

    return (
        !dataLoaded ? (
            <Loading />
        ) : (
            <div className="privacy-page">
                <HeaderFixed isPrivacyPage={true} />
                <Content data={data} />
            </div>
        )
    );
};

export default Privacy;
