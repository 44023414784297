import ReactHtmlParser from "react-html-parser";
import { ArrowRightCircleLight } from "../../assets/icons";
import OurSolutionsImg from "../../assets/about/our-solutions.jpeg";

function Soultions({ data }) {
    return (
        <div className="container">
            <div className="solutions-wrapper d-flex">
                {data.slice(4).map((solution) => (
                    <div
                        key={solution.id}
                        className={`single-solution-wrapper nav-tab --${solution.id}`}
                        id={(solution.title)}
                    >
                        <div className="text-block">
                            <h1 className="font-dark">
                                {ReactHtmlParser(solution.title)}
                            </h1>
                            <p>
                                {solution.description}
                            </p>
                            <button className="gradient-btn">
                                <a href={solution.button_link_2}>
                                    <span>View Products</span>
                                    <ArrowRightCircleLight />
                                </a>
                            </button>
                        </div>
                        <div className="image-block">
                            {/* <img src={solution.img_url} alt="product" /> */}
                            <img src={OurSolutionsImg} alt="product" />
                        </div>
                    </div>
                
                ))}
                {/* <div className="single-solution-wrapper nav-tab --1" id="Commercial">
                    <div className="text-block">
                        <h1 className="font-dark">
                            Commercial
                        </h1>
                        <p>
                            With H-Strut, Fittings and H-Block Rooftop solutions, we can tailor solutions to fit the precise support requirements, meet rigorous standards and help contractors address top challenges, like reducing labor costs and extending flexibility. 
                            Whether you're working with electrical, HVAC, plumbing, masonry, interior to the commercial structure or on the roof, Haydon ensures a secure, reliable support system where you need it.
                        </p>
                        <button className="gradient-btn">
                            <Link to="/catalog">
                                <span>View Products</span>
                                <ArrowRightCircleLight />
                            </Link>
                        </button>
                    </div>
                    <div className="image-block">
                        <img src={OurSolutionsImg} alt="product" />
                    </div>
                </div>
                <div className="single-solution-wrapper nav-tab --2" id="Residential">
                    <div className="text-block">
                        <h1 className="font-dark">
                            Residential
                        </h1>
                        <p>
                            Welcome to our electrical products , where we offer a range of UL-listed products designed to ensure safe and efficient electrical installations. Our products are suitable for use in raceway systems attached to
                            ceilings, steel beams, or embedded in concrete, guaranteeing precise alignment over extended areas. H-Strut channels empower electrical contractors to overcome challenges, including reducing labor costs and providing
                            increased flexibility.
                        </p>
                        <button className="gradient-btn">
                            <Link to="/catalog">
                                <span>View Products</span>
                                <ArrowRightCircleLight />
                            </Link>
                        </button>
                    </div>
                    <div className="image-block">
                        <img src={ProductImg} alt="product" />
                    </div>
                </div>
                <div className="single-solution-wrapper nav-tab --3" id="Infrastructure">
                    <div className="text-block">
                        <h1 className="font-dark">
                            Infrastructure
                        </h1>
                        <p>
                            Explore our range of H-STRUT Seismic hinge connectors designed to provide essential support for structural safety during seismic events. Our connectors are engineered to fit all H-STRUT 1-5/8" wide channels, ensuring
                            robust support for essential facilities. These specialty fittings, crafted from 1/4" thick carbon steel, are available in various finishes, making them suitable for critical infrastructure such as hospitals, medical
                            facilities with emergency treatment areas, fire stations, police stations, municipal government buildings, and natural disaster communication centers.
                        </p>
                        <button className="gradient-btn">
                            <Link to="/catalog">
                                <span>View Products</span>
                                <ArrowRightCircleLight />
                            </Link>
                        </button>
                    </div>
                    <div className="image-block">
                        <img src={OurSolutionsImg} alt="product" />
                    </div>
                </div>
                <div className="single-solution-wrapper nav-tab --4" id="Industrial">
                    <div className="text-block">
                        <h1 className="font-dark">
                            Industrial
                        </h1>
                        <p>
                            In the ever-evolving technology world, data centers bear the responsibility of reliability, and they demand a trusted partner to help them meet their objectives. As an experienced industry leader, we have earned a
                            reputation for our high-quality products and custom-designed solutions. Our unwavering reliability and commitment to delivering on our promises have cultivated customer loyalty that continues to grow. We specialize
                            in providing top-notch, convenient, and innovative solutions, which is why major brands turn to us for their metal framing system needs.
                        </p>
                        <button className="gradient-btn">
                            <Link to="/catalog">
                                <span>View Products</span>
                                <ArrowRightCircleLight />
                            </Link>
                        </button>
                    </div>
                    <div className="image-block">
                        <img src={ProductImg} alt="product" />
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Soultions;