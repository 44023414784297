import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Swiper from 'swiper/bundle';
import H1122ProductImg from '../../../assets/products/H-112-2.png';
import H11211ProductImg from '../../../assets/products/h112-11.png';
import { ChevronRightPurpleIcon, HeartDarkIcon, MetroChevronThinRightIcon, PdfLightSmIcon, ZoomIcon, ZoomOutIcon } from '../../../assets/icons';
import useAnimations from '../../../helpers/animations';

function OverView({ data, setLinkToDownload }) {
    useEffect(() => {
        const swiper = new Swiper(".thumbs-slider", {
            loop: true,
            direction: "vertical",
            spaceBetween: 15,
            speed: 500,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesProgress: true,
        });
        const swiper2 = new Swiper(".main-slider", {
            loop: true,
            direction: "vertical",
            spaceBetween: 10,
            speed: 500,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiper,
            },
        });

        //Image zoom
        const zoomButton = document.querySelector('.zoom');

        const zoomOutButton = document.querySelector('.zoom-out');

        // Add click event listener to the zoom button
        zoomButton.addEventListener('click', function (event) {
            event.stopPropagation();
            // Get the active slide
            const activeSlide = swiper2.slides[swiper2.activeIndex];
            // Get the image inside the active slide
            const activeImage = activeSlide.querySelector('img');
            // Get the source of the active image
            const activeImageSrc = activeImage.getAttribute('src');
            // Get the product image modal
            const productImageModal = document.querySelector('.product-image');
            // Set the source of the product image modal to the source of the active image
            productImageModal.setAttribute('src', activeImageSrc);

            const imageModal = document.querySelector('.product-image-zoom');


            imageModal.classList.add('opened');
        });

        // Add click event listener to the zoom-out button
        zoomOutButton.addEventListener('click', function () {

            const imageModal = document.querySelector('.product-image-zoom');

            imageModal.classList.remove('opened');
        });
    }, []);

    useEffect(() => {
        // Add click event listener to the document body to close zoom on outside click
        document.body.addEventListener('click', outsideClickHandler);

        // Function to handle clicks outside of the zoomed image
        function outsideClickHandler(event) {
            var imageModal = document.querySelector('.product-image-zoom');
            // Check if the clicked element is outside of the image modal
            if (!imageModal?.contains(event.target)) {
                // Close the image modal
                imageModal?.classList.remove('opened');
            }
        }
    }, []);

    // useEffect(() => {
    //     const handleAddToFavoritesClick = (event) => {
    //         const element = event.currentTarget;
    //         element.classList.toggle("added-to-favorites");
    //     };
    
    //     document.querySelectorAll(".add-to-favorites").forEach((element) => {
    //         element.addEventListener("click", handleAddToFavoritesClick);
    //     });
    
    //     return () => {
    //         document.querySelectorAll(".add-to-favorites").forEach((element) => {
    //             element.removeEventListener("click", handleAddToFavoritesClick);
    //         });
    //     };
    // }, []);

    useAnimations(({
        usedFor: 'catalog',
        sections: [
            {
                section: 'section.single-product-body ',
                animations: [
                    { target: 'section.overview .container'  },
                ]
            }
        ]
    }));

    return (
        <section className="overview bg-light nav-tab" id="Overview">
            <div className="container">
                <div className="product-image-zoom">
                    <div className="product-image-wrapper d-flex">
                        <div className="zoom-out">
                            <ZoomOutIcon />
                        </div>
                        <img src={data?.image_url[0]} alt="" className="product-image" />
                    </div>
                </div>
                <div className="gallery">
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                    <div thumbsslider="" className="swiper thumbs-slider">
                        <div className="swiper-wrapper">
                            {data?.image_url.map((url) => (
                                <div className="swiper-slide">
                                    <img src={url} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="swiper main-slider">
                        <div className="zoom">
                            <ZoomIcon />
                        </div>
                        <div className="swiper-wrapper">
                            {data?.image_url.map((url) => (
                                <div className="swiper-slide">
                                    <img src={url} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="overview-text">
                    <h6 className="font-dark">Overview</h6>
                    <p>{ReactHtmlParser(data?.details)}</p>
                </div>

                <div className="actions d-flex">
                    {data?.product_data_sheet.length > 0 && (
                        <div className="block">
                            <div className="title">Download</div>
                            <div className="small-text">Product data sheet</div>
                            {data?.product_data_sheet.map((sheet) => (
                                <button
                                    key={sheet.id}
                                    className="pdf download-form"
                                    onClick={() => {
                                        setLinkToDownload(sheet.pdf_link)
                                        if (localStorage.getItem('userSubmissionAccepted') === 'true') {
                                            window.open(sheet.pdf_link, '_blank')
                                        }
                                    }}
                                >
                                    <a>
                                        <PdfLightSmIcon />
                                        <span>{sheet.name}</span>
                                    </a>
                                </button>
                            ))}
                            {/* <button className="add">
                                <Link to="">
                                    <span>Add to Project</span>
                                    <MetroChevronThinRightIcon />
                                </Link>
                            </button> */}
                        </div>
                    )}
                    {/* <div className="favorites d-flex add-to-favorites">
                        <div className="add d-flex">
                            <HeartDarkIcon />
                            <span>Add to Favorites</span>
                        </div>
                        <ChevronRightPurpleIcon className="arrow" />
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default OverView;
