import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { ArrowLightCircleIcon } from '../../assets/icons';
import useAnimations from '../../helpers/animations';

function Info({ data }) {
    useAnimations({
        sections: [{
            section: 'section.about-body-section',
            animations: [
                { target: '.container > div.upper .text-block' },
                { target: '.container > div.upper .image img', scale: 1  },
                { target: '.container > div.bottom .text-block' },
                { target: '.container > div.bottom .image img', scale: 1  },
            ]
        }]
    });

    return (
        <section className="about-body-section bg-light">
            <div className="container">
                <div className="upper d-flex">
                    <div className="image">
                        <img src={data?.about_body_1.img_url} alt="team-members" />
                    </div>
                    <div className="text-block">
                        <h1 className="font-dark">
                            {/* Why <span> Haydon?</span> */}
                            {ReactHtmlParser(data?.about_body_1.title)}
                        </h1>
                        <div className="block d-flex">
                            <div className="d-flex">
                                <h2 className="font-dark">
                                    {/* 60 years */}
                                    {ReactHtmlParser(data?.about_body_1.description.slice(0, 9))}
                                </h2>
                                <div className="divider"></div>
                            </div>
                            <div className="d-flex">
                                <div className="divider"></div>
                                <div className="color-text">
                                    {/* of doing it better */}
                                    {ReactHtmlParser(data?.about_body_1.description.slice(11, 42))}
                                </div>
                            </div>
                        </div>
                        <p>
                            <strong>
                                {/* As a bold, independent family company, we build on our decades of experience to help you win. We combine local supplier flexibility with expansive national reach to deliver exceptional solutions and experiences. */}
                                {data?.about_body_1.description.slice(42, 255)}
                            </strong>
                            <br />
                            <br />
                            {/* With manufacturing and distribution facilities in New Jersey, Ohio, Texas and California, we provide easy accessibility to a wide product portfolio, ensuring orders arrive correctly, securely and on-time. */}
                            {data?.about_body_1.description.slice(255, 465)}
                            <br />
                            <br />
                            {/* From the first call to the last sign-off, we treat your project as our own, partnering with you every step of the way to success. */}
                            {data?.about_body_1.description.slice(465)}
                        </p>
                    </div>
                </div>
                <div className="bottom d-flex">
                    <div className="text-block">
                        <h2 className="font-dark">
                            {/* Our Commitment */}
                            {data?.about_body_2.title}
                        </h2>

                        <p>
                            <strong>
                                {/* We’re more than just a supplier. <br /> */}
                                {data?.about_body_2.description.slice(0, 32)} <br />
                                <br />
                                {/* We’re a partner. */}
                                {data?.about_body_2.description.slice(32, 55)}
                            </strong>
                            <br />
                            <br />
                            {/* As we continue to evolve, our commitment to partnership remains steadfast. Our priority will always be to help our customers, colleagues and communities succeed. */}
                            {data?.about_body_2.description.slice(55)}
                        </p>
                        <button className="gradient-btn">
                            <a href="/contact">
                                <span>
                                    {/* Partner with Us */}
                                    {data?.about_body_2.button_name}
                                </span>
                                <ArrowLightCircleIcon />
                            </a>
                        </button>
                    </div>
                    <div className="image">
                        <img src={data?.about_body_2.img_url} alt="team-member" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Info;