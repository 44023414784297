import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useEffect } from "react";
import {
    ArrowRightCircleLight,
    BoxLightSmIcon,
    HeartDarkIcon,
    InfoCircleSmIcon,
    NotesCircleIcon,
} from "../../../assets/icons";
import useAnimations from "../../../helpers/animations";

function TechInfo({ data }) {
    useEffect(() => {
        const handleAddToProjectClick = (event) => {
            const element = event.currentTarget;
            element.classList.toggle("added-to-project");
        
            const span = element.querySelector("span");
            if (span) {
                span.innerHTML = (span.innerHTML === "Add to Project") ? "Added" : "Add to Project";
            }
        };
    
        document.querySelectorAll(".add-to-project").forEach((element) => {
            element.addEventListener("click", handleAddToProjectClick);
        });
    
        return () => {
            document.querySelectorAll(".add-to-project").forEach((element) => {
                element.removeEventListener("click", handleAddToProjectClick);
            });
        };
    }, []);

    useEffect(() => {
        //Notifications
        document.querySelectorAll('.technical-info .add-to-favorites').forEach(function (button) {
            button.addEventListener('click', function () {
                if (button.classList.contains('added-to-favorites')) {
                    showBubble(this, 'Bookmarked');
                }
            });
        });

        document.querySelectorAll('.technical-info .add-to-project').forEach(function (button) {
            button.addEventListener('click', function () {
                if (button.classList.contains('added-to-project')) {
                    showBubble(this, 'Added to Project');
                }
            });
        });

        function showBubble(button, message) {
            const bubble = document.createElement('div');
            bubble.classList.add('bubble');
            bubble.textContent = message;
            button.appendChild(bubble);

            setTimeout(function () {
                bubble.parentNode.removeChild(bubble);
            }, 1000);
        }
    }, []);

    useAnimations(({
        usedFor: 'catalog',
        sections: [
            {
                section: 'section.single-product-body ',
                animations: [
                    { target: ' section.technical-info .container'},
                ]
            }
        ]
    }));

    return (
        <section className="technical-info bg-light nav-tab" id="Technical-Information">
            <div className="container">
                <h2 className="font-dark">
                    Technical Information
                </h2>
                {data?.technical_information_data.map((item) => (
                    <>
                        {item.title && (
                            <div className="text text-regular title">{ReactHtmlParser(item.title)}</div>
                        )}
                        {item.sub_title && (
                            <div className="text text-strong sub_title">{ReactHtmlParser(item.sub_title)}</div>
                        )}
                        {item.description && (
                            <div className="text text-regular description">{ReactHtmlParser(item.description)}</div>
                        )}
                        {item.table_headers && (
                            <div className="table-container">
                                <table style={{ width: "100%" }}>
                                    <thead>
                                        {item.table_headers.map((row) => (
                                            <tr key={row.id}>
                                                {row.headers.map((header, index) => (
                                                    header.colspan === 1 ?
                                                        <th key={index} rowSpan={header.rowspan}>{header.name}</th>
                                                        :
                                                        <th
                                                            key={index}
                                                            colSpan={header.colspan}
                                                        >
                                                            {header.name}
                                                        </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody>
                                        {item.table_data.map((row, index) => (
                                            <tr key={index}>
                                                {Object.keys(row).map((colName, colIndex) => (
                                                    colName !== 'product_id' && colName !== 'id' &&
                                                        <td key={colIndex}>{row[colName]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                        {/* <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66005
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>3</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66010
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>4</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66015
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>5</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66020
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>6</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66025
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>7</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66030
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>8</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66035
                                            </td>
                                            <td>Hi-Output 958 Complete</td>
                                            <td>
                                                Baseboard complete with 3/4" element with 2-1/2" x 3-1/4" aluminum fins and cover <br />
                                                (end caps not included)
                                            </td>
                                            <td>10</td>
                                            <td>3.5</td>
                                            <td>1</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66040
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>2</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66045
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>3</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66050
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>4</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66055
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>5</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66060
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>6</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66065
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>7</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66070
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>8</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                66075
                                            </td>
                                            <td>Hi-Output 958 Cover Only</td>
                                            <td>Baseboard cover/enclosure only</td>
                                            <td>10</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64100
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64102
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>3</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64105
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>4</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64107
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>5</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64108
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>6</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64110
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>7</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64112
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>3/4" Copper tube with 2-1/2" X 3-1/4" aluminum fins 958-2 (STD)</td>
                                            <td>8</td>
                                            <td>1</td>
                                            <td>4</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64115
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64117
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>3</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64120
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>4</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64122
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>5</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64125
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>6</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64127
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>7</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64130
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1" Copper tube with 2-3/4" X 3-1/4" aluminum fins 958-3 (STD)</td>
                                            <td>8</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64132
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>2</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64135
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>3</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64137
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>4</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64140
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>5</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64142
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>6</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64145
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>7</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="add-to-favorites">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.85" height="18.357" viewBox="0 0 18.85 18.357">
                                                        <g id="Icon_ionic-ios-heart" data-name="Icon ionic-ios-heart" transform="translate(0.75 0.75)">
                                                            <path
                                                                id="Icon_ionic-ios-heart-2"
                                                                data-name="Icon ionic-ios-heart"
                                                                d="M16.054,3.937h-.042A4.745,4.745,0,0,0,12.05,6.106,4.745,4.745,0,0,0,8.088,3.937H8.046A4.715,4.715,0,0,0,3.375,8.65a10.152,10.152,0,0,0,1.994,5.534A34.936,34.936,0,0,0,12.05,20.62a34.936,34.936,0,0,0,6.681-6.435A10.152,10.152,0,0,0,20.725,8.65,4.715,4.715,0,0,0,16.054,3.937Z"
                                                                transform="translate(-3.375 -3.938)"
                                                                fill="none"
                                                                stroke="#0a1e5b"
                                                                stroke-width="1.5"
                                                            />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <button className="add-to-project">
                                                    <a>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.589" height="18.591" viewBox="0 0 18.589 18.591">
                                                            <path
                                                                id="Path_313"
                                                                data-name="Path 313"
                                                                d="M8.368,14.978H3.657a.311.311,0,1,0,0,.622h4.71a.311.311,0,0,0,0-.623Zm3.593-8.41L13.621,8.4l1.661-1.828h-.359a.312.312,0,0,1-.313-.312V3.964H12.632V6.256a.313.313,0,0,1-.313.312ZM7.083,10.522,13.2,14.051l-1.039,1.8L6.044,12.321l1.039-1.8Zm.51-.425,4.415-2.549L13.391,9.07a.312.312,0,0,0,.461,0l1.384-1.523L19.65,10.1l-6.029,3.481L7.593,10.1Zm6.341,5v5.977l6.027-3.48V13.754l-4.834,2.791a.311.311,0,0,1-.425-.115l-.768-1.33Zm6.226-4.578-6.113,3.53,1.039,1.8L21.2,12.321l-1.038-1.8ZM7.751,16.307a.311.311,0,1,1,0,.623H7.282V17.6l6.027,3.48V15.1l-.768,1.33a.311.311,0,0,1-.425.115L7.282,13.753v.536a.312.312,0,0,1-.625,0v-.9L5.464,12.7a.311.311,0,0,1-.115-.425L6.7,9.941a.372.372,0,0,1,.114-.114h0l4.765-2.751-.554-.61a.312.312,0,0,1,.23-.522h.753V3.651a.312.312,0,0,1,.312-.312h2.6a.313.313,0,0,1,.312.313V5.943h.752a.312.312,0,0,1,.219.536l-.542.6,4.748,2.741a.3.3,0,0,1,.14.14l1.341,2.322a.311.311,0,0,1-.115.425l-1.192.689v4.384a.313.313,0,0,1-.176.281L13.8,21.877a.31.31,0,0,1-.35,0l-6.633-3.83a.312.312,0,0,1-.155-.27V16.93h-1.7a.311.311,0,1,1,0-.623Z"
                                                                transform="translate(-3.346 -3.339)"
                                                                fill="#fff"
                                                                fill-rule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Add to Project</span>
                                                    </a>
                                                </button>
                                                64147
                                            </td>
                                            <td>Hi-Output 958 Element Only</td>
                                            <td>1-1/4" Copper tube with 3" X 3-1/4" aluminum fins 958-5 (STD)</td>
                                            <td>8</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td></td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {item.notes && (
                            <div className="single-table-notes__text">
                                {ReactHtmlParser(item.notes)}
                            </div>
                        )}
                    </>
                ))}
                {/* <div className="cta d-flex">
                    <div className="left d-flex">
                        <img src="images/icons/info-circle-sm.svg" alt="i-circle" />
                        <span>Bearing Load may limit load | ** Not recommended - KL/r exceeds 200</span>
                    </div>
                    <button className="request gradient-btn">
                        <a href="/contact">
                            <span>Build Submittal Package</span>
                            <img src="images/icons/arrow-right-circle-light.svg" alt="arrow-right" />
                        </a>
                    </button>
                </div> */}
                {data?.technical_information_notes && (
                    <div className="notes">
                        <div className="title d-flex">
                            <NotesCircleIcon />
                            <h5 className="font-dark">NOTES</h5>
                        </div>
                        <div className="body-text">
                            <div dangerouslySetInnerHTML={{ __html: decodeHtml(data?.specification_notes) }} />
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default TechInfo;
