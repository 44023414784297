import ReactHtmlParser from 'react-html-parser';
import useAnimations from "../../helpers/animations";

function About({ data }) {
    useAnimations({
        sections: [
            {
                section: 'section.about-section',
                animations: [
                    { target: 'div.right-column .text-block' },
                    { target: 'div.left-column .image-block:first-child .image img', scale: 1 },
                    { target: 'div.right-column .image-block .image img', scale: 1 },
                    { target: 'div.left-column .image-block:nth-child(2) .image img', scale: 1 },
                    { target: 'div.left-column .image-block:first-child h4' },
                    { target: 'div.left-column .image-block:first-child p' },
                    { target: 'div.left-column .image-block:nth-child(2) h4' },
                    { target: 'div.left-column .image-block:nth-child(2) p' },
                    { target: 'div.right-column .image-block h4' },
                    { target: 'div.right-column .image-block p' }
                ]
            },
        ]
    });

    return (
        <section className="about-section hp bg-light">
            <div className="container">
                <div className="left-column d-flex">
                    <div className="image-block d-flex">
                        <div className="image">
                            <img src={data?.about_1.img_url} alt="team-members" />
                        </div>
                        <h4 className="font-dark">{ReactHtmlParser(data?.about_1.title)}</h4>
                        <p>
                            {/* Independence is part of our DNA. It shows in all of our associates and their commitment to our
                            unified goal: fulfilling every order on time and on spec. <br />
                            Everyone at Haydon is empowered to take initiative, tackle problems, be bold and act in the best
                            interests of our colleagues, partners and communities. */}
                            {data?.about_1.description}
                        </p>
                        <button>
                            <a href={data?.about_1.button_link} className="d-flex">
                                <span>{data?.about_1.button_name}</span>
                                <img src={data?.about_1.button_icon} alt="arrow-right" />
                            </a>
                        </button>
                    </div>
                    <div className="image-block d-flex">
                        <div className="image">
                            <img src={data?.about_3.img_url} alt="team-member" />
                        </div>
                        <h4 className="font-dark">{ReactHtmlParser(data?.about_3.title)}</h4>
                        <p>
                            {/* <strong>We're more than a supplier. We're a partner.
                            </strong> <br /><br />
                            Where others see challenge, we see opportunity. At Haydon, we’re meeting that opportunity by
                            drawing on our independence and experience to deliver fresh ideas, innovative solutions and
                            quality experiences. We continuously find new ways to support our customers and help their
                            businesses grow. */}
                            {data?.about_3.description}
                        </p>
                        <button>
                            <a href={data?.about_3.button_link} className="d-flex">
                                <span>{data?.about_3.button_name}</span>
                                <img src={data?.about_3.button_icon} alt="arrow-right" />
                            </a>
                        </button>
                    </div>
                </div>
                <div className="right-column d-flex">
                    <div className="text-block">
                        <h1 className="font-dark">
                            {/* Who<span>We</span> Are */}
                            {ReactHtmlParser(data?.about_header.title)}
                        </h1>
                        <p>
                            {/* Our spark is what drives our purpose, values and commitment to finding solutions for our
                            customers. As a family-owned business, our people, partners and communities are our spark,
                            fueling every decision and action we take. We believe the experiences we create for them are the
                            ultimate driver of our success. */}
                            {data?.about_header.description}
                        </p>
                    </div>
                    <div className="image-block d-flex">
                        <div className="image">
                            <img src={data?.about_2.img_url} alt="product" />
                        </div>
                        <h4 className="font-dark">{ReactHtmlParser(data?.about_2.title)}</h4>
                        <p>
                            {/* Our range of innovative, adaptable products ensures a solution for every construction need.
                            Equally important, our products arrive to you correctly, securely and on time. */}
                            {data?.about_2.description}
                        </p>
                        <button>
                            <a href={data?.about_2.button_link} className="d-flex">
                                <span>{data?.about_2.button_name}</span>
                                <img src={data?.about_2.button_icon} alt="arrow-right" />
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;