import ReactHtmlParser from "react-html-parser";
import TechDataBannerImg from "../../../assets/banner/banner.avif";
import Banner from "../../../components/Banner";

function TechDataBanner({ data }) {
    return (
        <Banner
            banner="tech-data-banner"
            backgroundBanner={TechDataBannerImg}
            activeTab="resources"
        >
            <div className="container">
                <div className="text-content d-flex">
                    <h1 className="font-light">
                        {/* Technical<span>Data</span> */}
                        {ReactHtmlParser(data?.technical_data_header.title)}
                    </h1>
                    <div className="text">
                        {/* We have a wide range of product literature to help our customers learn more about our
                        products and help you sell products for upcoming jobs. */}
                        {data?.technical_data_header.description}
                    </div>
                </div>
            </div>
        </Banner>
    );
};

export default TechDataBanner;
