import { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import gsap from 'gsap';
import Swiper from 'swiper/bundle';
import Banner from "../../components/Banner";
import useAnimations from "../../helpers/animations";
import { ArrowDownCircleIcon } from "../../assets/icons";

function HPBanner({ data }) {
    //HP Slider Banner
    useEffect(() => {
        const swiper = new Swiper('.swiper-banner', {
            effect: "fade",

            speed: 1000,
            autoplay: {
                delay: 10000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + ' <svg height="52" width="52" fill="none" color="#807CE0" xmlns="http://www.w3.org/2000/svg"> <circle class="circle" cx="26" cy="26" r="25" fill="none" stroke="#807CE0" stroke-width="2" fill-opacity="0" /></svg></span>';
                },
            },

        });

        var globalTimeline = gsap.timeline();

        function animateBullet(index) {
            globalTimeline.to(".swiper-pagination span:nth-child(" + index + ") svg circle", {
                strokeDashoffset: 0,
                duration: 9.5
            });
            globalTimeline.to(".swiper-pagination span:nth-child(" + index + ")", {
                background: "linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%)",
            });
        }


        animateBullet(1);


        swiper.on('slideChange', function () {

            var activeIndex = swiper.activeIndex;

            globalTimeline.clear();


            gsap.set(".swiper-pagination span svg circle", { strokeDashoffset: 160 });
            gsap.set(".swiper-pagination span", { background: "" });

            animateBullet(activeIndex + 1);
        });
    }, []);

    useAnimations({
        usedFor: 'homepage',
        sections: [],
    });

    return (
        <Banner activeTab="home">
            <div className="swiper swiper-banner">
                <div className="swiper-wrapper">
                    {data?.slider.map((slide) => (
                        <div key={slide.id} className="swiper-slide">
                            <img src={slide.img_url} alt="" className="desktop-bg" />
                            <img src={slide.img_url_2} alt="" className="mobile-bg" />
                            <div className="container">
                                <div className="text-content d-flex">
                                    <h1 className="font-light">
                                        {ReactHtmlParser(slide.title)}
                                    </h1>
                                    <div className="text">{slide.description}</div>
                                    <div className="discover ">
                                        <Link to={slide.button_link} className="d-flex">
                                            <span>{ReactHtmlParser(slide.button_name)}</span>
                                            <ArrowDownCircleIcon />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="container">
                <div className="swiper-pagination"></div>
            </div>
            <div className="scroll d-flex">
                <span>Scroll to discover</span>
                <div className="mouse d-flex">
                    <div></div>
                </div>
            </div>
        </Banner>
    );
};

export default HPBanner;
