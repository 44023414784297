import ReactHtmlParser from "react-html-parser";
import OurSolutionsBannerImg from "../../assets/banner/our-solutions.jpg";
import Banner from "../../components/Banner";

function OurSolutionsBanner({ data }) {

    return (
        <Banner
            banner="our-solutions-banner"
            backgroundBanner={OurSolutionsBannerImg}
        >
            <div className="container">
                <div className="text-content d-flex">
                    <h1 className="font-light">
                        {/* Our <span>Solutions</span> */}
                        {ReactHtmlParser(data?.solutions_header?.title)}
                    </h1>
                    <div className="text">
                        {/* Partner with us to bring your project to life. We understand that different sectors face unique challenges, and we are committed to delivering solutions quickly and efficiently. */}
                        {data?.solutions_header?.description}
                    </div>
                </div>
            </div>
        </Banner>
    );
};

export default OurSolutionsBanner;
