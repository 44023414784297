import BaseService from './BaseService';

class NewsService extends BaseService {
    getNewsWithQuery = (search, date) => this.get(`/news-articles/?q=${search}&date=${date}&format=json`);

    getNews = () => this.get('/news-articles/?format=json');

    getSingleNews = (id) => this.get(`/view-news/?news_id=${id}&format=json`);

    getListOfYearsAndTags = () => this.get('/news-articles-archives/?format=json');
}

export default new NewsService();
