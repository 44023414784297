import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import WorkingTeam from '../../assets/about/bussiness-people-working-team-office.png';
import Team1 from '../../assets/about/team1.png';
import Team2 from '../../assets/about/team2.png';
import Team3 from '../../assets/about/team3.png';
import Team4 from '../../assets/about/team4.png';
import Team5 from '../../assets/about/team5.png';
import { ArrowLightCircleIcon, LogoHIcon } from '../../assets/icons';
import useAnimations from '../../helpers/animations';

function Team({ data, boardMembers, executiveLeadership, seniorLeadership }) {
    // useEffect(() => {
    //     document.getElementById('defaultOpen').click();
    // }, []);
    
    const openTab = (evt, tabName) => {
        let i;
        let tabcontent = document.getElementsByClassName('team-members');
        let tablinks = document.querySelectorAll('.team-wrapper .categories .category');
    
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = 'none';
        }
    
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].classList.remove('active');
        }
    
        document.getElementById(tabName).style.display = 'flex';
        evt.currentTarget.classList.add('active');
    };

    useAnimations({
        sections: [{
            section: 'section.team',
            animations: [
                // { target: '.container .text-block' },
                // { target: '.container .team-wrapper .categories'  },
                // { target: '.container .team-wrapper .team-members .single-team-member-wrapper .single-team-member', stagger: 0.2 },
                { target: '.container .careers-block'  },
            
            ]
        }]
    });

    return (
        <section className="team bg-dark">
            <div className="container">
                {/* <div className="text-block">
                    <h1 className="font-light">
                        {data?.team_members_header.title}
                    </h1>
                    <div className="text">
                        {data?.team_members_header.description}
                    </div>
                </div>
                <div className="team-wrapper d-flex">
                    <div className="categories d-flex">
                        <div className="category" onClick={(e) => openTab(e, 'board-members')} id="defaultOpen">
                            Board Members
                        </div>
                        <div className="category" onClick={(e) => openTab(e, 'executive-leadership')}>
                            Executive Leadership
                        </div>
                        <div className="category" onClick={(e) => openTab(e, 'senior-leadership')}>
                            Senior Leadership
                        </div>
                    </div>
                    <div className="team-members" id="board-members">
                        {boardMembers?.map((member) => (
                            <div key={member.id} className="single-team-member-wrapper">
                                <div className="single-team-member">
                                    <div className="image">
                                        <div className="image-wrapper">
                                            <img src={Team2} alt="team-member" />
                                        </div>
                                        <div className="btn-container">
                                            <button className="outline-btn">
                                                <Link to="">Read Bio</Link>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="information">
                                        <div className="name">
                                            {member.first_name}
                                            {' '}
                                            {member.last_name}
                                        </div>
                                        <div className="position">{member.position}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="team-members" id="executive-leadership">
                        {executiveLeadership?.map((member) => (
                            <div key={member.id} className="single-team-member-wrapper">
                                <div className="single-team-member">
                                    <div className="image">
                                        <div className="image-wrapper">
                                            <img src={Team2} alt="team-member" />
                                        </div>
                                        <div className="btn-container">
                                            <button className="outline-btn">
                                                <Link to="">Read Bio</Link>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="information">
                                        <div className="name">
                                            {member.first_name}
                                            {' '}
                                            {member.last_name}
                                        </div>
                                        <div className="position">{member.position}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="team-members" id="senior-leadership">
                        {seniorLeadership?.map((member) => (
                            <div key={member.id} className="single-team-member-wrapper">
                                <div className="single-team-member">
                                    <div className="image">
                                        <div className="image-wrapper">
                                            <img src={Team2} alt="team-member" />
                                        </div>
                                        <div className="btn-container">
                                            <button className="outline-btn">
                                                <Link to="">Read Bio</Link>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="information">
                                        <div className="name">
                                            {member.first_name}
                                            {' '}
                                            {member.last_name}
                                        </div>
                                        <div className="position">{member.position}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className="careers-block d-flex">
                    <div className="left d-flex">
                        <div className="bg-img">
                            <img src={WorkingTeam} alt="people-working" />
                        </div>
                        <h1 className="font-light">Careers</h1>
                    </div>
                    <div className="right">
                        <div className="bg-logo">
                            <LogoHIcon />
                        </div>
                        <div className="careers-information">
                            <div className="title-style-1">
                                {/* WE’RE */}
                                {data?.team_members_footer_2.title.slice(0, 5)}
                            </div>
                            <div className="title-style-2">
                                {/* HIRING! */}
                                {data?.team_members_footer_2.title.slice(5)}
                            </div>
                            <p>
                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at vehicula nisl. */}
                                {data?.team_members_footer_2.description}
                            </p>
                            <button className="gradient-btn">
                                <a href={data?.team_members_footer_2.button_link} target="_blank">
                                    <span>
                                        {/* Work with us */}
                                        {data?.team_members_footer_2.button_name}
                                    </span>
                                    <ArrowLightCircleIcon />
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;