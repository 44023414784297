import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get solutions
    requestGetSolutions: [],
    getSolutionsSucceeded: ['solutions'],
    getSolutionsFailed: ['error'],

    // get team members
    requestGetTeamMembers: [],
    getTeamMembersSucceeded: [
        'boardMembers',
        'executiveLeadership',
        'seniorLeadership',
    ],
    getTeamMembersFailed: ['error'],

    // get certifications
    requestGetCertifications: [],
    getCertificationsSucceeded: ['certifications'],
    getCertificationsFailed: ['error'],

    // get faqs
    requestGetFaqs: ['filters'],
    getFaqsSucceeded: ['faqs'],
    getFaqsFailed: ['error'],

    // get suggestions for faqs
    requestGetFaqSuggestions: ['filters'],
    getFaqSuggestionsSucceeded: ['faqSuggestions'],
    getFaqSuggestionsFailed: ['error'],

    // get literature
    requestGetLiterature: ['filters'],
    getLiteratureSucceeded: ['literature'],
    getLiteratureFailed: ['error'],

    // get suggestions for literature
    requestGetLiteratureSuggestions: ['filters'],
    getLiteratureSuggestionsSucceeded: ['literatureSuggestions'],
    getLiteratureSuggestionsFailed: ['error'],

    // get technical data
    requestGetTechnicalData: ['filters'],
    getTechnicalDataSucceeded: ['technicalData'],
    getTechnicalDataFailed: ['error'],

    // get suggestions for technical data
    requestGetTechnicalDataSuggestions: ['filters'],
    getTechnicalDataSuggestionsSucceeded: ['techDataSuggestions'],
    getTechnicalDataSuggestionsFailed: ['error'],

    // send user data to download pdf
    requestDownloadPdf: ['data'],
    downloadPdfSucceeded: ['message'],
    downloadPdfFailed: ['error'],
});

export default createReducer(initialState, {
    // get solutions
    [types.REQUEST_GET_SOLUTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_SOLUTIONS_SUCCEEDED]: (state, { solutions }) => ({
        ...state,
        solutions,
        loading: false,
    }),
    [types.GET_SOLUTIONS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get team members
    [types.REQUEST_GET_TEAM_MEMBERS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_TEAM_MEMBERS_SUCCEEDED]: (state, {
        boardMembers,
        executiveLeadership,
        seniorLeadership,
    }) => ({
        ...state,
        boardMembers,
        executiveLeadership,
        seniorLeadership,
        loading: false,
    }),
    [types.GET_TEAM_MEMBERS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get certifications
    [types.REQUEST_GET_CERTIFICATIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_CERTIFICATIONS_SUCCEEDED]: (state, { certifications }) => ({
        ...state,
        certifications,
        loading: false,
    }),
    [types.GET_CERTIFICATIONS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get faqs
    [types.REQUEST_GET_FAQS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_FAQS_SUCCEEDED]: (state, { faqs }) => ({
        ...state,
        faqs,
        loading: false,
    }),
    [types.GET_FAQS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get suggestions for faqs
    [types.REQUEST_GET_FAQ_SUGGESTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_FAQ_SUGGESTIONS_SUCCEEDED]: (state, { faqSuggestions }) => ({
        ...state,
        faqSuggestions,
        loading: false,
    }),
    [types.GET_FAQ_SUGGESTIONS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get literature
    [types.REQUEST_GET_LITERATURE]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_LITERATURE_SUCCEEDED]: (state, { literature }) => ({
        ...state,
        literature,
        loading: false,
    }),
    [types.GET_LITERATURE_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get suggestions for literature
    [types.REQUEST_GET_LITERATURE_SUGGESTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_LITERATURE_SUGGESTIONS_SUCCEEDED]: (state, { literatureSuggestions }) => ({
        ...state,
        literatureSuggestions,
        loading: false,
    }),
    [types.GET_LITERATURE_SUGGESTIONS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get technical data
    [types.REQUEST_GET_TECHNICAL_DATA]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_TECHNICAL_DATA_SUCCEEDED]: (state, { technicalData }) => ({
        ...state,
        technicalData,
        loading: false,
    }),
    [types.GET_TECHNICAL_DATA_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get suggestions for technical data
    [types.REQUEST_GET_TECHNICAL_DATA_SUGGESTIONS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_TECHNICAL_DATA_SUGGESTIONS_SUCCEEDED]: (state, { techDataSuggestions }) => ({
        ...state,
        techDataSuggestions,
        loading: false,
    }),
    [types.GET_TECHNICAL_DATA_SUGGESTIONS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // send user data to download pdf
    [types.REQUEST_DOWNLOAD_PDF]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.DOWNLOAD_PDF_SUCCEEDED]: (state, { message }) => ({
        ...state,
        message,
        loading: false,
    }),
    [types.DOWNLOAD_PDF_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),
});

export { types, actions };
