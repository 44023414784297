import ReactHtmlParser from "react-html-parser";
import Banner from "../../../components/Banner";
import useAnimations from "../../../helpers/animations";

function CrossReffBanner({ data }) {
    useAnimations({
        usedFor: 'entry-banner',
        sections: [],
    });

    return (
        <Banner
            banner="cross-ref-banner"
            backgroundBanner={data?.cross_reference_header.img_url}
            activeTab="resources"
        >
            <div className="container">
                <div className="text-content d-flex">
                    <h1 className="font-light">{ReactHtmlParser(data?.cross_reference_header.title)}</h1>
                    <div className="text">
                        {data?.cross_reference_header.description}
                    </div>
                </div>
            </div>
        </Banner>
    );
};

export default CrossReffBanner;
