import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import gsap from "gsap";
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import { updateTitle } from "../../../helpers/utils";
import { actions } from "../../../redux/news/reducer";
import SideBar from "./SideBar";
import MainContent from "./MainContent";

function News() {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect (() => {
        const data = {
            search: queryParams.get('search'),
            date: queryParams.get('date'),
        }
        dispatch(actions.requestGetNews(data));
    }, [queryParams]);

    useEffect(() => {
        //Animations
        let entryTl = gsap.timeline();
        entryTl.fromTo('.news-body .container .sidebar-wrapper .sidebar', {}, {
            opacity: 1,
            y: 0,
            duration:0.5
        })

        entryTl.fromTo('.news-body .container .main-content', {}, {
            opacity: 1,
            y: 0,
            duration:0.5
        }, "<")
    }, []);

    useEffect(() => {
        updateTitle('Haydon - News');
    }, []);

    return (
        <div className="news-page">
            <HeaderFixed activeTab="resources" subMenuActive="news" />
            <section className="news-body">
                <div className="container">
                    <SideBar />
                    <MainContent />
                </div>
            </section>
        </div>
    );
};

export default News;
