import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import MenuService from '../../services/MenuService';
import { types, actions } from './reducer';

function* getMenu() {
    try {
        const response = yield call(MenuService.getMenu);
        const {header_menu: headerMenu, footer_menu: footerMenu} = response.data;
        yield put(actions.getMenuSucceeded(headerMenu, footerMenu));
    } catch (error) {
        yield put(actions.getMenuFailed(extractError(error)));
    }
}

function* getMenuCategories() {
    try {
        const response = yield call(MenuService.getMenuCategories);
        const {
            catalog: catalogMenu,
            products_by_sector: productsBySectorMenu,
        } = response.data;
        yield put(actions.getMenuCategoriesSucceeded(
            catalogMenu,
            productsBySectorMenu,
        ));
    } catch (error) {
        yield put(actions.getMenuCategoriesFailed(extractError(error)));
    }
}

function* watchGetMenu() {
    yield takeEvery(types.REQUEST_GET_MENU, getMenu);
}

function* watchGetMenuCategories() {
    yield takeEvery(types.REQUEST_GET_MENU_CATEGORIES, getMenuCategories);
}

function* menuSaga() {
    yield all([
        fork(watchGetMenu),
        fork(watchGetMenuCategories),
    ]);
}

export default menuSaga;
