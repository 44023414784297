import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import StickyNavigation from "../../components/StickyNavigation";
import HeaderFixed from "../../components/MainLayout/HeaderFixed";
import { updateTitle } from "../../helpers/utils";
import OurSolutionsBanner from "./OurSolutionsBanner";
import Soultions from "./Solutions";

function OurSolutions () {
    const [dataLoaded, setDataLoaded] = useState(false);

    const solutionsPage = useSelector((state) => state.staticData.solutionsPage);
    const solutions = useSelector((state) => state.dynamicData.solutions);

    useEffect(() => {
        if (solutions.length > 0) {
            setDataLoaded(true);
        }
    }, [solutions]);

    const textAnimations = solutions.slice(4).map((solution) => ({
        target: `.container .solutions-wrapper .single-solution-wrapper.--${solution.id} .text-block`
    }));

    const imageAnimations = solutions.slice(4).map((solution) => ({
        target: `.container .solutions-wrapper .single-solution-wrapper.--${solution.id} .image-block img`, scale: 1
    }));

    useEffect(() => {
        updateTitle('Haydon - Our Solutions');
    }, []);

    return (
        <div className="our-solutions-page">
            <HeaderFixed />
            <OurSolutionsBanner data={solutionsPage?.section_1} />
            <section className="solutions-body bg-light">
                <StickyNavigation
                    bodyClass=".solutions-body"
                    usedFor="entry-banner"
                    sectionElement="section.solutions-body"
                    targetElements={[
                        { target: '.navigation-sticky-container' },
                        ...textAnimations,
                        ...imageAnimations,
                    ]}
                >
                    {solutions.slice(4).map((solution) => (
                        <a
                            key={solution.id}
                            href={`#${(solution.title)}`}
                            className={`single-navigation ${solution.id === 1 ? 'active' : ''}`}
                        >
                            {ReactHtmlParser(solution.title)}
                        </a>
                    ))}
                </StickyNavigation>
                {dataLoaded &&
                    <Soultions
                        data={solutions}
                    />
                }
            </section>
        </div>
    );
};

export default OurSolutions;
