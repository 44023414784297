import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import NewsService from '../../services/NewsService';
import { types, actions } from './reducer';

function* getNews({ filters }) {
    if (filters && (filters.search || filters.date)) { 
        const {
            search: searchValue,
            date: dateValue,
        } = filters;
        try {
            const response = yield call(NewsService.getNewsWithQuery, searchValue, dateValue);
            yield put(actions.getNewsSucceeded(response.data));
        }
        catch (error) {
            yield put(actions.getNewsFailed(extractError(error)));
        }
    } else {
        try {
            const response = yield call(NewsService.getNews);
            yield put(actions.getNewsSucceeded(response.data));
        } catch (error) {
            yield put(actions.getNewsFailed(extractError(error)));
        }
    }
}

function* getSingleNews({ id }) {
    try {
        const response = yield call(NewsService.getSingleNews, id);
        yield put(actions.getSingleNewsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getSingleNewsFailed(extractError(error)));
    }
}

function* getListOfYearsAndTags() {
    try {
        const response = yield call(NewsService.getListOfYearsAndTags);
        const {
            tags: tagList,
            years: yearList,
        } = response.data;
        yield put(actions.getListOfYearsAndTagsSucceeded(
            yearList,
            tagList,
        ));
    } catch (error) {
        yield put(actions.getListOfYearsAndTagsFailed(extractError(error)));
    }
}

function* watchGetNews() {
    yield takeEvery(types.REQUEST_GET_NEWS, getNews);
}

function* watchGetSingleNews() {
    yield takeEvery(types.REQUEST_GET_SINGLE_NEWS, getSingleNews);
}

function* watchGetListOfYearsAndTags() {
    yield takeEvery(types.REQUEST_GET_LIST_OF_YEARS_AND_TAGS, getListOfYearsAndTags);
}

function* newsSaga() {
    yield all([
        fork(watchGetNews),
        fork(watchGetSingleNews),
        fork(watchGetListOfYearsAndTags),
    ]);
}

export default newsSaga;
