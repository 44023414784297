import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLightCircleIcon, ArrowRightLightLgIcon } from '../../assets/icons';
import { QuestionsIcon, NewsIcon } from '../../assets/illustrations';
import { actions } from '../../redux/contact/reducer';
import useAnimations from "../../helpers/animations";

function Form ({ data }) {
    const dispatch = useDispatch();

    const formData = useSelector(state => state.contact.contactForm);
    const message = useSelector(state => state.contact.message);

    const [formDataToSubmit, setFormDataToSubmit] = useState({
        question_id: '',
        customer_id: '',
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        location: '',
        product_of_interest: '',
        address: '',
        comments: '',
        product_model: '',
        product_variation: '',
        quantity_needed: '',
        existing_systems: '',
        specific_installation: '',
    });

    useEffect(() => {
        dispatch(actions.requestGetContactForm());
    }, []);
    
    // submit form data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDataToSubmit({
            ...formDataToSubmit,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(actions.requestSubmitContactForm(formDataToSubmit));
    };

    useEffect(() => {
        if(message === 'success') {
            document.getElementById('successMessage').classList.add('active');
            setTimeout(function() {
                dispatch(actions.clearMessage());
                setFormDataToSubmit({
                    question_id: '',
                    customer_id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    company: '',
                    location: '',
                    product_of_interest: '',
                    address: '',
                    comments: '',
                    product_model: '',
                    product_variation: '',
                    quantity_needed: '',
                    existing_systems: '',
                    specific_installation: '',
                });
            }, 20000);
            window.scrollTo({
                top: 0,
            });
        }
    }, [message]);

    // SELECT BASED INPUTS
    const questionTypeSelect = document.getElementById('questionType');
    const locationFieldsDiv = document.getElementById('locationFields');
    const shippingFieldsDiv = document.getElementById('shippingFields');
    const productFieldsDiv = document.getElementById('productFields');
    const quantityFieldsDiv = document.getElementById('quantityFields');
    const installationFieldsDiv = document.getElementById('installationFields');
    const otherFieldsDiv = document.getElementById('otherFields');

    useEffect(() => {
        function hideAllFields() {
            locationFieldsDiv.classList.add('hiddenFields');
            shippingFieldsDiv.classList.add('hiddenFields');
            productFieldsDiv.classList.add('hiddenFields');
            quantityFieldsDiv.classList.add('hiddenFields');
            installationFieldsDiv.classList.add('hiddenFields');
            otherFieldsDiv.classList.add('hiddenFields');
        }
    
        // Event listener for select change
        function handleQuestionTypeChange() {
            // console.log('questionTypeSelect.value', questionTypeSelect.value);
            // Hide all additional field divs
            hideAllFields();
            // Show relevant field div based on selected option
            switch (questionTypeSelect.value) {
                case '0': // 'location':
                    locationFieldsDiv.classList.remove('hiddenFields');
                    break;
                case '1': // 'shipping':
                    shippingFieldsDiv.classList.remove('hiddenFields');
                    break;
                case '2': // 'product':
                    productFieldsDiv.classList.remove('hiddenFields');
                    break;
                case '3': // 'quantity':
                    quantityFieldsDiv.classList.remove('hiddenFields');
                    break;
                case '4': // 'installation':
                    installationFieldsDiv.classList.remove('hiddenFields');
                    break;
                case '5': // 'other':
                    otherFieldsDiv.classList.remove('hiddenFields');
                    break;


            }
        };
        questionTypeSelect?.addEventListener('change', handleQuestionTypeChange);

        return () => {
            questionTypeSelect?.removeEventListener('change', handleQuestionTypeChange);
        };
    }, [questionTypeSelect]);

    useAnimations({
        usedFor: 'contactpage',
        sections: [
            {
                section: 'section.contact-body',
                animations: [
                    { target: '.container .map iframe' },
            
                ]
            },
            {
                section: 'section.contact-details',
                animations: [
                    { target: '.container h2' },
                    { target: '.container .single-detail-wrapper .single-detail', stagger: 0.2 },

                
                ]
            }
        ]
    });

    return (
        <section className="contact-body bg-light">
            <div className="container">
                <div className="main-content d-flex">
                    <div className="left">
                        <h1 className="font-dark">
                            {/* Contact <span>Us</span> */}
                            {ReactHtmlParser(data?.contact_us_header.title)}
                        </h1>
                        <p>
                            {/* Can’t find what you’re looking for or need more information? <br />
                            Check our Frequently Asked Questions or submit a question and our team will get back to you as soon as possible. */}
                            {data?.contact_us_header.description}
                        </p>
                        <div className="blocks d-flex">
                            <div className="block">
                                <a href="/faq">
                                    <QuestionsIcon />
                                    <span>FAQs</span>
                                    <ArrowRightLightLgIcon />
                                </a>
                            </div>
                            <div className="block">
                                <a href="https://www.linkedin.com/company/haydon-corporation-inc./jobs/" target="_blank">
                                    <NewsIcon />
                                    <span>Careers</span>
                                    <ArrowRightLightLgIcon />
                                </a>
                            </div>
                        </div>
                    </div>
                    {message === 'success' ? (
                        <div className="right">
                            <div className="message-wrapper">
                                <div id="successMessage" className="success-message">
                                    <h4>
                                        Thank you for your submission!
                                    </h4>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="right">
                            <div className="form-wrapper d-flex">
                                <h2>
                                    {data?.contact_form_header.title}
                                </h2>
                                <p>
                                    {data?.contact_form_header.description}
                                </p>
                                <form action="" className="d-flex" onSubmit={handleSubmit}>
                                    <div className="input-block">
                                        <label htmlFor="question">{formData?.[0]?.label}</label> <br />
                                        <select
                                            id="questionType"
                                            name="question_id"
                                            onChange={handleInputChange}
                                            defaultValue={'default'}
                                        >
                                            <option value="default" disabled>Please Select</option>
                                            {formData?.[0]?.choices_list?.map((option) => (
                                                <option
                                                    key={option[0]}
                                                    value={option[0]}
                                                >
                                                    {option[1]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="question">{formData?.[1]?.label}</label> <br />
                                        <select
                                            id="question"
                                            name="customer_id"
                                            onChange={handleInputChange}
                                            defaultValue={'default'}
                                        >
                                            <option value="default" disabled>Please Select</option>
                                            {formData?.[1]?.choices_list?.map((option) => (
                                                <option
                                                    key={option[0]}
                                                    value={option[0]}
                                                >
                                                    {option[1]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="fname">{`${formData?.[2]?.label}`}</label><br />
                                        <input type="text" id="fname" name="first_name" required onChange={handleInputChange} />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="lname">{`${formData?.[3]?.label}`}</label><br />
                                        <input type="text" id="lname" name="last_name" required onChange={handleInputChange} />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="email">{`${formData?.[4]?.label}`}</label><br />
                                        <input type="email" id="email" name="email" required onChange={handleInputChange} />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="company">{`${formData?.[5]?.label}`}</label><br />
                                        <input type="text" id="company" name="company" required onChange={handleInputChange} />
                                    </div>
                                    <div id="locationFields" className="hiddenFields additional-fields">
                                        <div className="input-block full">
                                            <label htmlFor="location">{`${formData?.[6]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="location"
                                                name="location"
                                                required={formDataToSubmit.question_id === '0'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="locationProductsOI">{`${formData?.[7]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="locationProductsOI"
                                                name="product_of_interest"
                                                required={formDataToSubmit.question_id === '0'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div id="shippingFields" className="hiddenFields additional-fields">
                                        <div className="input-block full">
                                            <label htmlFor="address">{`${formData?.[8]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                required={formDataToSubmit.question_id === '1'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="shippingProductsOI">{`${formData?.[7]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="shippingProductsOI"
                                                name="product_of_interest"
                                                required={formDataToSubmit.question_id === '1'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="shippingMessage">{`${formData?.[9]?.label}`}</label><br />
                                            <textarea name="comments" id="" cols="20" rows="6" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div id="productFields" className="hiddenFields additional-fields">
                                        <div className="input-block">
                                            <label htmlFor="productname">{`${formData?.[10]?.label}`}</label><br />
                                            <select
                                                id="productname"
                                                name="product_model"
                                                onChange={handleInputChange}
                                                defaultValue={'default'}
                                            >
                                                <option value="default" disabled>Please Select</option>
                                                {formData?.[10]?.choices_list?.map((option) => (
                                                    <option
                                                        key={option[0]}
                                                        value={option[0]}
                                                    >
                                                        {option[1]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-block">
                                            <label htmlFor="productVariation">{`${formData?.[11]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="productVariation"
                                                name="product_variation"
                                                required={formDataToSubmit.question_id === '2'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="productMessage">{`${formData?.[9]?.label}`}</label><br />
                                            <textarea name="comments" id="" cols="20" rows="6" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div id="quantityFields" className="hiddenFields additional-fields">
                                        <div className="input-block full">
                                            <label htmlFor="quantityProductsOI">{`${formData?.[7]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="quantityProductsOI"
                                                name="product_of_interest"
                                                required={formDataToSubmit.question_id === '3'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="quantityNeeded">{`${formData?.[12]?.label}`}</label><br />
                                            <input
                                                type="number"
                                                id="quantityNeeded"
                                                name="quantity_needed"
                                                required={formDataToSubmit.question_id === '3'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="quantityMessage">{`${formData?.[9]?.label}`}</label><br />
                                            <textarea name="comments" id="" cols="20" rows="6" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div id="installationFields" className="hiddenFields additional-fields">
                                        <div className="input-block full">
                                            <label htmlFor="installationProductsOI">{`${formData?.[7]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="installationProductsOI"
                                                name="product_of_interest"
                                                required={formDataToSubmit.question_id === '4'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="installationDetails">{`${formData?.[13]?.label}`}</label><br />
                                            <input
                                                type="text"
                                                id="installationDetails"
                                                name="existing_systems"
                                                required={formDataToSubmit.question_id === '4'}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="input-block full">
                                            <label htmlFor="installationMessage">{`${formData?.[14]?.label}`}</label><br />
                                            <textarea name="specific_installation" id="" cols="20" rows="6" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div id="otherFields" className="hiddenFields additional-fields">
                                        <div className="input-block full">
                                            <label htmlFor="otherMessage">{`${formData?.[9]?.label}`}</label><br />
                                            <textarea name="comments" id="" cols="20" rows="6" onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="consent">
                                        <a href="/terms-of-use">{data?.contact_form_footer.description}</a>
                                    </div>
                                    <button type="submit" className="outline-btn style-2">
                                        <a>
                                            <span>{data?.contact_form_footer.button_name}</span>
                                            <ArrowLightCircleIcon />
                                        </a>
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
                <div className="map">
                    <iframe
                        title="Google Map"
                        src={process.env.REACT_APP_GOOGLE_MAPS_API_URL}
                        width="800"
                        height="600"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
            </div>
        </section>
    );
};

export default Form;
