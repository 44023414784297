import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get news
    requestGetNews: ['filters'],
    getNewsSucceeded: ['news'],
    getNewsFailed: ['error'],

    // get single news  
    requestGetSingleNews: ['id'],
    getSingleNewsSucceeded: ['singleNews'],
    getSingleNewsFailed: ['error'],

    // get list of years and tags
    requestGetListOfYearsAndTags: [],
    getListOfYearsAndTagsSucceeded: [
        'listOfYears',
        'tags',
    ],
    getListOfYearsAndTagsFailed: ['error'],
});

export default createReducer(initialState, {
    // get news
    [types.REQUEST_GET_NEWS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_NEWS_SUCCEEDED]: (state, { news }) => ({
        ...state,
        news,
        loading: false,
    }),
    [types.GET_NEWS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get single news
    [types.REQUEST_GET_SINGLE_NEWS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_SINGLE_NEWS_SUCCEEDED]: (state, { singleNews }) => ({
        ...state,
        singleNews,
        loading: false,
    }),
    [types.GET_SINGLE_NEWS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get list of years and tags
    [types.REQUEST_GET_LIST_OF_YEARS_AND_TAGS]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_LIST_OF_YEARS_AND_TAGS_SUCCEEDED]: (state, { listOfYears, tags }) => ({
        ...state,
        listOfYears,
        tags,
        loading: false,
    }),
    [types.GET_LIST_OF_YEARS_AND_TAGS_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),
});

export { types, actions };
