const model = {
    solutions: [],
    boardMembers: [],
    executiveLeadership: [],
    seniorLeadership: [],
    certifications: [],
    faqs: [],
    literature: [],
    technicalData: [],
    literatureSuggestions: [],
    techDataSuggestions: [],
    faqSuggestions: [],
    message: '',
    loading: false,
    error: null,
};

export default model;
