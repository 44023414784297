import ReactHtmlParser from "react-html-parser";
import Banner from "../../components/Banner";
import useAnimations from "../../helpers/animations";

function AboutBanner({ data }) {;
    useAnimations({
        usedFor: 'entry-banner',
        sections: [],
    });

    return (
        <Banner
            banner="about-page-banner"
            backgroundBanner={data?.about_header.img_url}
            activeTab="about-haydon"
        >
            <div className="container">
                <div className="text-content d-flex">
                    <h1 className="font-light">
                        {/* About <span>Us</span> */}
                        {ReactHtmlParser(data?.about_header.title)}
                    </h1>
                    <div className="text">
                        {/* We're a passionate team dedicated to fostering meaningful connections with our
                        customers and fueling their business growth. We leverage our extensive experience to deliver
                        innovative solutions that address real challenges within our industry. As a family-owned business,
                        we prioritize agility, empower our employees and treat our customers as partners. This allows us to
                        create high-quality products and deliver exceptional experiences */}
                        {data?.about_header.description}
                    </div>
                </div>
            </div>
        </Banner>
    );
};

export default AboutBanner;
