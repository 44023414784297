import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import SubscriptionDownloadForm from "../../../components/SubscriptionDownloadForm";
import StickySearch from "../../../components/StickySearch";
import StickyNavigation from "../../../components/StickyNavigation";
import { actions } from "../../../redux/dynamic_data/reducer";
import { updateTitle } from "../../../helpers/utils";
import TechDataBanner from "./TechDataBanner";
import TechDataSearch from "./TechDataSearch";

function TechnicalData() {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchValue = queryParams.get('search');

    const [dataLoaded, setDataLoaded] = useState(false);
    const [linkToDownload, setLinkToDownload] = useState('');

    const technicalDataPage = useSelector((state) => state.staticData.technicalDataPage);
    const data = useSelector(state => state.dynamicData.technicalData);

    useEffect(() => {
        dispatch(actions.requestGetTechnicalData({ search: searchValue?.split('_').join(' ') }));
    }, [dispatch, searchValue]);

    useEffect(() => {
        if (data.length > 0) {
            setDataLoaded(true);
        }
    }, [data]);

    useEffect(() => {
        updateTitle('Haydon - Technical Data');
    }, []);
    
    return (
        <div className="tech-data-page">
            <HeaderFixed activeTab="resources" />
            {dataLoaded && <SubscriptionDownloadForm linkToDownload={linkToDownload} />}
            <TechDataBanner data={technicalDataPage?.section_1} />
            <section className="technical-body bg-grey search-fixed-surrounding-body">
                <StickySearch usedFor='technical-data' />
                <StickyNavigation
                    bodyClass=".technical-body"
                    usedFor="entry-banner"
                    sectionElement="section.technical-body"
                    targetElements={[
                        { target: '.search-sticky-container' },
                        { target: '.navigation-sticky-container' },
                    ]}
                >
                    {data?.map((item) => (
                        <a href={`#${item.name.split(' ').join('-')}`} key={item.id} className={`single-navigation ${item.id === 0 ? 'active' : ''}`}>{item.name}</a>
                    ))}
                    {/* <a href="#strut" className="single-navigation active">STRUT </a>
                    <a href="#roof-blocks" className="single-navigation">Roof Blocks </a>
                    <a href="#baseboard" className="single-navigation">Baseboard</a> */}
                </StickyNavigation>
                {dataLoaded && <TechDataSearch data={data} setLinkToDownload={setLinkToDownload} />}
            </section>
        </div>
    );
};

export default TechnicalData;