import { useEffect } from 'react';

function Loading() {
    useEffect(() => {
        const handleLoad = () => {
            document.querySelector('body').classList.add('loaded');
        };

        window.addEventListener('load', handleLoad);
    
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return (
        <div className="loader-wrapper">
            <div className="loader"></div>
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
        </div>
    )
};

export default Loading;
