import { all } from 'redux-saga/effects';
import statickDataSaga from './static_data/saga';
import menuSaga from './menu/saga';
import dynamicDataSaga from './dynamic_data/saga';
import newsSaga from './news/saga';
import contactSaga from './contact/saga';
import catalogSaga from './catalog/saga';
import crossReferenceSaga from './cross_reference/saga';

export default function* rootSaga() {
    yield all([
        statickDataSaga(),
        menuSaga(),
        dynamicDataSaga(),
        newsSaga(),
        contactSaga(),
        catalogSaga(),
        crossReferenceSaga(),
    ]);
}