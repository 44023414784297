import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderFixed from "../../components/MainLayout/HeaderFixed";
import AboutBanner from "./AboutBanner";
import Certifications from "./Certifications";
import Info from "./Info";
import Team from "./Team";
import { actions } from '../../redux/dynamic_data/reducer';
import { updateTitle } from '../../helpers/utils';

function About() {
    const dispatch = useDispatch();
    // const [teamDataLoaded, setTeamDataLoaded] = useState(false);
    const [certificationsDataLoaded, setCertificationsDataLoaded] = useState(false);

    const aboutPage = useSelector((state) => state.staticData.aboutPage);
    // const boardMembers = useSelector((state) => state.dynamicData.boardMembers);
    // const executiveLeadership = useSelector((state) => state.dynamicData.executiveLeadership);
    // const seniorLeadership = useSelector((state) => state.dynamicData.seniorLeadership);
    const certifications = useSelector((state) => state.dynamicData.certifications);

    useEffect(() => {
        // if (boardMembers.length !== 0) {
        //     setTeamDataLoaded(true);
        // }
        if (certifications.length !== 0) {
            setCertificationsDataLoaded(true);
        }
    }, [certifications]); //[boardMembers, certifications]

    useEffect(() => {
        dispatch(actions.requestGetTeamMembers());
    }, [dispatch]);

    useEffect(() => {
        updateTitle('Haydon - About');
    }, []);

    return (
        <div className="about-page">
            <HeaderFixed activeTab="about-haydon" />
            <AboutBanner data={aboutPage?.section_1} />
            <Info data={aboutPage?.section_2} />
            {/* {teamDataLoaded && (
                <Team
                    data={aboutPage?.section_3}
                    boardMembers={boardMembers}
                    executiveLeadership={executiveLeadership}
                    seniorLeadership={seniorLeadership}
                />
            )} */}
            <Team
                data={aboutPage?.section_3}
            />
            {certificationsDataLoaded && (
                <Certifications data={aboutPage?.section_4} certifications={certifications} />
            )}
        </div>
    );
};

export default About;