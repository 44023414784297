import {
    ArrowRightCircleDarkIcon,
    ArrowRightCircleLightSm,
    PdfIcon,
} from "../../../assets/icons";

function TechDataSearch({ data, setLinkToDownload }) {
    return (
        data?.map((item) => (
            <section className={`data ${item.name === "H-Strut" ? 'bg-grey' : 'bg-dark'} nav-tab --${item.id}`} id={item.name.split(' ').join('-')} key={item.id}>
                <div className="container">
                    <h3 className={`${item.name === "H-Strut" ? 'font-dark' : 'font-light'}`}>{item.name}</h3>
                    <div className="data-wrapper d-flex">
                        {item.files.map((file) => (
                            <div className="single-data-wrapper technical-data" key={file.id}>
                                <div className="single-data">
                                    <PdfIcon />
                                    <div className="divider"></div>
                                    <div className="title">{file.name}</div>
                                    <button className="download-form" onClick={() => {
                                        setLinkToDownload(file.pdf)
                                        if (localStorage.getItem('userSubmissionAccepted') === 'true') {
                                            window.open(file.pdf, '_blank')
                                        }
                                    }}>
                                        <a>View Document</a>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="btn-wrapper d-flex">
                        <button className="outline-btn">
                            <a href="/catalog">
                                <span>View {item.name} Products</span>
                                {item.name === "H-Strut"
                                    ? <ArrowRightCircleDarkIcon />
                                    : <ArrowRightCircleLightSm />}
                            </a>
                        </button>
                        {/* <button className="gradient-btn">
                            <Link to="">
                                <span>Request a Quote</span>
                                <ArrowRightCircleLightSm />
                            </Link>
                        </button> */}
                    </div>
                </div>
            </section>
        ))
    );
};

export default TechDataSearch;
