import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftLongLightSmIcon, ArrowRightLongLightSmIcon, SearchDarkIcon, CloseMobileIcon, DeleteIcon } from "../../../assets/icons";
import { actions } from "../../../redux/news/reducer";

function SideBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [search, setSearch] = useState(queryParams.get('search') || '');

    const years = useSelector((state) => state.news.listOfYears);
    const tags = useSelector((state) => state.news.tags);

    // handle change year
    const [currentYearIndex, setCurrentYearIndex] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(queryParams.get('date')?.split('-')[1]);

    useEffect(() => {
        if (years.length !== 0) {
            setCurrentYearIndex(years.length - 1);
        }
    }, [years]);

    const handlePrevYear = () => {
        setCurrentYearIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };
    
    const handleNextYear = () => {
        setCurrentYearIndex((prevIndex) =>
            Math.min(prevIndex + 1, years.length - 1)
        );
    };

    const currentYearData = years[currentYearIndex];

    // handle search change
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        if (e.target.value === '') {
            queryParams.delete('search');
            navigate(`?${queryParams.toString()}`);
            return;
        }
        queryParams.set('search', e.target.value);
        navigate(`?${queryParams.toString()}`);
    };

    // handle tag click
    const handleTagClick = (tag) => {
        queryParams.set('search', tag.toLowerCase());
        navigate(`?${queryParams.toString()}`);
    };

    // handle month click
    const handleMonthClick = (month, year) => {
        setSelectedMonth(month);
        queryParams.set('date', `${year}-${month}`);
        navigate(`?${queryParams.toString()}`);
    };

    // get list of years
    useEffect(() => {
        dispatch(actions.requestGetListOfYearsAndTags());
    }, [dispatch]);

    return (
        <div className="sidebar-wrapper">
            <div className="sidebar">
                <form>
                    <input
                        type="text"
                        placeholder="Quick search..." 
                        name="search"
                        className="searchInput"
                        value={search}
                        onChange={handleSearchChange}
                    />
                    {search !== '' ?
                        <button
                            type="button"
                            className="clear"
                            onClick={() => {
                                setSearch('');
                                queryParams.delete('search');
                                navigate(`?${queryParams.toString()}`);
                            }}
                        >
                            <CloseMobileIcon />
                        </button>
                        :
                        <button disabled type="submit" className="submit">
                            <SearchDarkIcon />
                        </button>
                    }
                </form>
                <div className="tags">
                    <div className="title">
                        Tags
                    </div>
                    <div className="tags-wrapper d-flex">
                        {tags.map((tag, index) => (
                            <div
                                className="single-tag"
                                key={index}
                                onClick={() => {
                                    handleTagClick(tag);
                                    setSearch(tag);
                                }}
                            >
                                {tag}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="archive-filter">
                    <div className="title">Archive</div>
                    <div className="filters d-flex">
                        <div className="year-wrapper d-flex">
                            <div className="prev" onClick={handlePrevYear}>
                                <ArrowLeftLongLightSmIcon />
                            </div>
                            <div className="year d-flex">
                                <span>Year:</span>
                                <div id="current">{currentYearData?.year}</div>
                            </div>
                            <div className="next" onClick={handleNextYear}>
                                <ArrowRightLongLightSmIcon />
                            </div>
                        </div>
                        <div className="months d-flex">
                            <div
                                className={`month d-flex ${selectedMonth === undefined && 'active'}`}
                                onClick={() => {
                                    setSelectedMonth(undefined);
                                    queryParams.delete('date'),
                                    navigate(`?${queryParams.toString()}`)
                                }}
                            >
                                <span id="month">All archive</span>
                            </div>
                            {currentYearData?.months.map((month) => (
                                <div
                                    className={`month d-flex ${month.month === selectedMonth && 'active'}`}
                                    key={month.id}
                                    onClick={() => handleMonthClick(month.month, currentYearData.year)}
                                >
                                    <span id="month">{month.name}</span>
                                    <span id="year">{currentYearData.year}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {(queryParams.get('search') || queryParams.get('date')) && (
                    <div
                        className="clear-filters"
                        onClick={() => {
                            setSelectedMonth(undefined);
                            queryParams.delete('search');
                            queryParams.delete('date');
                            navigate(`?${queryParams.toString()}`);
                        }}
                    >
                        <DeleteIcon />
                        Clear filters
                    </div>
                )}
            </div>
        </div>
    );
};

export default SideBar;
