import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchModal from './SearchModal';
import MobileNav from './MobileNav';
import Footer from './Footer';

function MainLayout({ children }) {
    const [loadMobileMenu, setLoadMobileMenu] = useState(false);

    const menu = useSelector((state) => state.menu.headerMenu);
    const catalogMenu = useSelector((state) => state.menu.catalogMenu);
    const productsBySectorMenu = useSelector((state) => state.menu.productsBySectorMenu);

    useEffect(() => {
        if (menu.length > 0 && catalogMenu.length > 0) {
            setTimeout(function() {
                setLoadMobileMenu(true);
            }, 200);
        }
    }, [menu, catalogMenu]);

    return (
        <div className="main-layout">
            <div className="main-content">
                <SearchModal />
                {loadMobileMenu &&
                    <MobileNav
                        menu={menu}
                        catalogMenu={catalogMenu}
                        productsBySectorMenu={productsBySectorMenu}
                    />
                }
                {children}
            </div>
            <Footer />
        </div>
    );
      
}

export default MainLayout;
