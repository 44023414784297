const model = {
    catalogCategories: [],
    catalogUrl: [],
    productList: [],
    productLoading: false,
    productListSearch: [],
    productDetails: null,
    loading: false,
    error: null,
}

export default model;