import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import gsap from 'gsap';
import $ from 'jquery';
import {
    ChevronRightPurpleIcon,
    ChevronDownDarkMdIcon,
    HeartLightMdIcon,
    HomePurpleIcon,
    CloseMobileIcon,
} from '../../assets/icons';

const removeTag = (checkedCategories, tag, product) => {
    const newCheckedCategories = checkedCategories.map((category) => {
        if (category.id === product.id) {
            const newChildren = category.children.filter((child) => child.id !== tag.id);
            return {
                ...category,
                children: newChildren,
            };
        }
        return category;
    });
    return newCheckedCategories;
};

const allChildrenUnchecked = (allChildren) => {
    let allUnchecked = true;
    allChildren.forEach((child) => {
        if (child.checked) {
            allUnchecked = false;
        }
    });
    return allUnchecked;
};

function ProductList({
    data,
    checkedCategories,
    setCheckedCategories,
    catalogUrlList,
}) {
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const removeSlugFromUrl = (matchingItem) => {
        // Get all segments, ignoring empty segments (from leading/trailing slashes)
        const urlObject = new URL(window.location.href);
        const pathname = urlObject.pathname;
        const pathSegments = pathname.split('/').filter(Boolean);

        // const indexToRemove = 1; // Alternatively, remove by index
        const indexOfSegment = pathSegments.indexOf(matchingItem.slug);
    
        if (indexOfSegment !== -1) {
            // Remove the segment by index
            pathSegments.splice(indexOfSegment, 1);
        }
    
        // Construct the new path
        const newPath = `/${pathSegments.join('/')}`;
        navigate(`${newPath}`);
    };

    const unCheckSidebarCategory = (categoryId, product) => {
        const categoryElement = document.getElementById(categoryId);
        categoryElement.checked = false;
        const parent = document.getElementsByClassName(`sub ${categoryId}`)[0];
        const grandParent = document.getElementsByClassName(`main ${product.id}`)[0];
    
        const allChildren = document.querySelectorAll(`.category-block-wrapper .category-block .body .ch-${parent?.value}`);
        const IfAllChildrenUnchecked = allChildrenUnchecked(allChildren);
        if (IfAllChildrenUnchecked) {
            if (parent) {
                parent.checked = false;
                const matchingItem = catalogUrlList.find(item => item.id === parseInt(parent.value, 10));
                if (matchingItem) {
                    removeSlugFromUrl(matchingItem);
                }
            }
        }
        const allSubCategories = document.querySelectorAll(`.category-block-wrapper .category-block .body .s-${product.id}`);
        const IfAllSubCategoriesUnchecked = allChildrenUnchecked(allSubCategories);
        if (IfAllSubCategoriesUnchecked) {
            if (grandParent) {
                grandParent.checked = false;
                const matchingItem = catalogUrlList.find(item => item.id === parseInt(grandParent.value, 10));
                if (matchingItem) {
                    removeSlugFromUrl(matchingItem);
                }
            }
        }
    };

    useEffect(() => {
        const menuCategories = document.querySelectorAll('.subcategories-blocks .subcategory-block .head');
        const viewMoreBtn = document.querySelector('.catalog-body .container .main-content .selected-filters .view-more .view-more-btn');

        const toggleDropdown = (element, contentSelector) => {
            const $element = $(element); // Convert to jQuery object
            $element.toggleClass('opened');
            $element.find(contentSelector).slideToggle(300);
        };
    
        const handleCategoryClick = (event) => {
            const category = event.currentTarget;
            const element = category.closest('.subcategory-block');
            toggleDropdown(element, '.body');
        };

        const handleViewMoreClick = (event) => {
            const viewMore = event.currentTarget;
            const element = viewMore.closest('.view-more');
            toggleDropdown(element, '.view-more-body');

            if (element.classList.contains('opened')) {
                viewMoreBtn.textContent = 'View Less';
            } else {
                viewMoreBtn.textContent = 'View More';
            }
        };
    
        menuCategories.forEach(category => {
            category.addEventListener('click', handleCategoryClick);
        });

        viewMoreBtn?.addEventListener('click', handleViewMoreClick);
    
        return () => {
            menuCategories.forEach(category => {
                category.removeEventListener('click', handleCategoryClick);
            });
        };
    }, [data, windowWidth]);

    useEffect(() => {
        let entryTl = gsap.timeline();
        entryTl.fromTo('.catalog-body .container .main-content',{},{
            opacity:1,
            y:0,
            duration:0.5
        },"<")
    }, []);


    useEffect(() => {
        let isFilterFixed = {};

        function handleFilterScroll() {
            let filters = document.querySelectorAll(".selected-filters-wrapper");
            let observers = document.querySelectorAll(".catalog-category-custom-margin");

            observers.forEach(function (observer, index) {
                let observerRect = observer.getBoundingClientRect();
                let isScrolledPast = observerRect.top <= 0;

                if (isScrolledPast && !isFilterFixed[index]) {
                    filters[index].classList.add("is-fixed");
                    isFilterFixed[index] = true;
                } else if (!isScrolledPast && isFilterFixed[index]) {
                    filters[index].classList.remove("is-fixed");
                    isFilterFixed[index] = false;
                }
            });
        }

        window.addEventListener('scroll', handleFilterScroll);

        return () => {
            window.removeEventListener('scroll', handleFilterScroll);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="main-content d-flex">
            {data.map((item) => (
                <div key={item.id} className="catalog-category-custom-margin">
                    <h1 className="font-dark">{item.name}</h1>
                    <div className="main-text">
                        {ReactHtmlParser(item.desc)}
                    </div>
                    <div className="selected-filters-wrapper d-flex">
                        <div className="sf-title">
                            {item.name}
                        </div>
                        <div className="selected-filters d-flex">
                            {checkedCategories.map((category) => {
                                if (category.id === item.id) {
                                    if (category.children.length > 4 && windowWidth < 1200) {
                                        return (
                                            <>
                                                {category.children?.slice(0, 4).map((child) =>
                                                    <div key={child.id} className="selected-filter">
                                                        <span>{child.name}</span>
                                                        <button onClick={() => {
                                                            setCheckedCategories(removeTag(checkedCategories, child, item));
                                                            unCheckSidebarCategory(child.id, item);
                                                            removeSlugFromUrl(catalogUrlList.find(item => item.id === child.id));
                                                        }}>
                                                            <CloseMobileIcon className="remove-filter" />
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="view-more">
                                                    <div className="view-more-body">
                                                        <div className="view-more-body-wrapper">
                                                            {category.children?.slice(4).map((child) =>
                                                                <div key={child.id} className="selected-filter">
                                                                    <span>{child.name}</span>
                                                                    <button onClick={() => {
                                                                        setCheckedCategories(removeTag(checkedCategories, child, item));
                                                                        unCheckSidebarCategory(child.id, item);
                                                                        removeSlugFromUrl(catalogUrlList.find(item => item.id === child.id));
                                                                    }}>
                                                                        <CloseMobileIcon className="remove-filter" />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="view-more-btn">
                                                        View More
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    } else {
                                        return (
                                            category.children?.map((child) =>
                                                <div key={child.id} className="selected-filter">
                                                    <span>{child.name}</span>
                                                    <button onClick={() => {
                                                        setCheckedCategories(removeTag(checkedCategories, child, item));
                                                        unCheckSidebarCategory(child.id, item);
                                                        removeSlugFromUrl(catalogUrlList.find(item => item.id === child.id));
                                                    }}>
                                                        <CloseMobileIcon className="remove-filter" />
                                                    </button>
                                                </div>
                                            )
                                        );
                                    }
                                }
                            })}
                        </div>
                    </div>
                    {item.categories.map((category) => (
                        category.categories !== null ? (
                            <div key={category.id} className="category-level-two">
                                <h3 className="font-dark">{category.name}</h3>
                                <div className="cl2-text">
                                    {ReactHtmlParser(category.desc)}
                                </div>
                                <div className="subcategories-blocks d-flex">
                                    {category.categories.map((subcategory, index) => (
                                        <div
                                            key={subcategory.id}
                                            className={`subcategory-block ${index === 0 ? 'opened' : ''}`}
                                        >
                                            <div className="head">
                                                <div className="upper">
                                                    <div className="line"></div>
                                                    <div className="icon">
                                                        <div><ChevronDownDarkMdIcon /></div>
                                                    </div>
                                                </div>
                                                <h6 className="font-dark">{subcategory.name}</h6>
                                            </div>
                                            <div className="body">
                                                <div className="description">
                                                    {ReactHtmlParser(subcategory.desc)}
                                                </div>
                                                <div className="products-wrapper">
                                                    {subcategory.products.map((product) => (
                                                        <div key={product.id} className="single-product-wrapper">
                                                            <div className="single-product">
                                                                <div className="product-image">
                                                                    <img src={product.image_url} alt="product" />
                                                                </div>
                                                                <div className="title">{product.name}</div>
                                                                <div className="actions">
                                                                    <button className="view">
                                                                        <a
                                                                            href={`/catalog/product/${product.slug}?id=${product.id}&category=${subcategory.id}`}
                                                                        >
                                                                            <span>View Product</span>
                                                                        </a>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div key={category.id} className="category-level-two">
                                <h3 className="font-dark">{category.name}</h3>
                                <div className="cl2-text">
                                    {ReactHtmlParser(category.desc)}
                                </div>
                                <div className="subcategories-blocks d-flex">
                                    <div
                                        key={category.id}
                                        className="subcategory-block opened"
                                    >
                                        <div className="head">
                                            <div className="upper">
                                                <div className="line"></div>
                                                <div className="icon">
                                                    <div><ChevronDownDarkMdIcon /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body">
                                            <div className="products-wrapper">
                                                {category.products.map((product) => (
                                                    <div key={product.id} className="single-product-wrapper">
                                                        <div className="single-product">
                                                            <div className="product-image">
                                                                <img src={product.image_url} alt="product" />
                                                            </div>
                                                            <div className="title">{product.name}</div>
                                                            <div className="actions">
                                                                <button className="view">
                                                                    <a
                                                                        href={`/catalog/product/${product.slug}?id=${product.id}&category=${category.id}`}
                                                                    >
                                                                        <span>View Product</span>
                                                                    </a>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            ))}
        </div>
    )
};

export default ProductList;
