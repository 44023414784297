import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppRouter from './routes';
import Loading from './components/Loading';
import { actions } from './redux/static_data/reducer';
import { actions as menuActions } from './redux/menu/reducer';
import { actions as solutionsActions } from './redux/dynamic_data/reducer';
import { actions as certificationActions } from "./redux/dynamic_data/reducer";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.requestGetStaticData());
        dispatch(menuActions.requestGetMenu());
        dispatch(menuActions.requestGetMenuCategories());
        dispatch(solutionsActions.requestGetSolutions());
        dispatch(certificationActions.requestGetCertifications());
    }, [dispatch]);

    // useEffect(() => {
    //     const handleLoad = () => {
    //         document.querySelector('body').classList.add('loaded');
    //     };

    //     window.addEventListener('load', handleLoad);
    
    //     return () => {
    //         window.removeEventListener('load', handleLoad);
    //     };
    // }, []);

    return (
        <Suspense fallback={<Loading />}>
            <AppRouter />
        </Suspense>
    );
}

export default App;
