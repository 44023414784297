import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowRightCirclePurpleIcon } from "../../../assets/icons";
import StickySearch from "../../../components/StickySearch";
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import { actions } from "../../../redux/dynamic_data/reducer";
import { updateTitle } from "../../../helpers/utils";
import FaqBanner from "./FaqBanner";
import Faqs from "./Faqs";

function Faq() {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [dataLoaded, setDataLoaded] = useState(false);

    const faqPage = useSelector((state) => state.staticData.faqsPage);
    const faqs = useSelector((state) => state.dynamicData.faqs);

    useEffect(() => {
        dispatch(actions.requestGetFaqs({ search: id }));
    }, [dispatch, id]);

    useEffect(() => {
        if (faqs.length > 0) {
            setDataLoaded(true);
        }
    }, [faqs]);

    useEffect(() => {
        updateTitle('Haydon - FAQs');
    }, []);

    return (
        <div className="faq-page">
            <HeaderFixed activeTab="resources" />
            <FaqBanner data={faqPage?.section_1} />
            <section className="faqs-body bg-grey search-fixed-surrounding-body">
                <StickySearch usedFor="faq" />
                {dataLoaded &&
                    <Faqs data={faqs} />
                }
                <div className="cta d-flex">
                    <div className="text">
                        {/* Don't see what you need? */}
                        {faqPage?.section_2.faqs_footer.title}
                    </div>
                    <button className="outline-btn">
                        <Link to={faqPage?.section_2.faqs_footer.button_link}>
                            <span>
                                {/* Contact Us */}
                                {faqPage?.section_2.faqs_footer.button_name}
                            </span>
                            <ArrowRightCirclePurpleIcon />
                        </Link>
                    </button>
                </div>
            </section>
        </div>
    );
};

export default Faq;
