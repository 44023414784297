import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { ArrowRightDarkSmIcon } from "../../../assets/icons";
// import NewsIcon from "../../../assets/news/20230926_HAYDON_00181147.png";

function MainContent() {
    const news = useSelector(state => state.news.news);
    const loading = useSelector(state => state.news.loading);
    const newsPage = useSelector(state => state.staticData.newsPage);

    return (
        loading ? (
            <div className="loader" />
        ) : (
            <div className="main-content d-flex">
                <h1 className="font-dark">{ReactHtmlParser(newsPage?.section_1.news_header.title)}</h1>
                <p>{newsPage?.section_1.news_header.description}</p>
                <div className="news-wrapper d-flex">
                    {news.map((newsItem) => (
                        <div key={newsItem.id} className="single-news-wrapper">
                            <div className="single-news">
                                <div className="image">
                                    <a href={`/news-single?${newsItem.title.split(' ').join('-').toLowerCase()}&newsId=${newsItem.id}`}>
                                        <div className="date">{newsItem.publish_date}</div>
                                        <div className="category">{newsItem.tags[0]}</div>
                                        <img src={newsItem.cover_media} alt="" />
                                    </a>
                                </div>
                                <h6 className="font-dark">{newsItem.title}</h6>
                                <a
                                    className="read-more"
                                    href={`/news-single?${newsItem.title.split(' ').join('-').toLowerCase()}&newsId=${newsItem.id}`}
                                >
                                    <span>Read More</span>
                                    <ArrowRightDarkSmIcon />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    );
};

export default MainContent;
