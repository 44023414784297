import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({

    // get categories
    requestGetCategories: [],
    getCategoriesSucceeded: [
        'catalogCategories',
        'catalogUrl',
    ],
    getCategoriesFailed: ['error'],

    // get product list
    requestGetProductList: ['filters'],
    getProductListSucceeded: ['data'],
    getProductListFailed: ['error'],

    // get product list (search component)
    requestGetProductListSearch: ['filters'],
    getProductListSearchSucceeded: ['data'],
    getProductListSearchFailed: ['error'],

    // view product
    requestViewProduct: ['data'],
    viewProductSucceeded: ['data'],
    viewProductFailed: ['error'],
});

export default createReducer(initialState, {
    // get categories
    [types.REQUEST_GET_CATEGORIES]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_CATEGORIES_SUCCEEDED]: (state, {
        catalogCategories,
        catalogUrl,
    }) => ({
        ...state,
        catalogCategories,
        catalogUrl,
        loading: false,
    }),
    [types.GET_CATEGORIES_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // get product list
    [types.REQUEST_GET_PRODUCT_LIST]: (state) => ({
        ...state,
        productLoading: true,
    }),
    [types.GET_PRODUCT_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        productList: data,
        productLoading: false,
    }),
    [types.GET_PRODUCT_LIST_FAILED]: (state, { error }) => ({
        ...state,
        error,
        productLoading: false,
    }),

    // get product list (search component)
    [types.REQUEST_GET_PRODUCT_LIST_SEARCH]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_PRODUCT_LIST_SEARCH_SUCCEEDED]: (state, { data }) => ({
        ...state,
        productListSearch: data,
        loading: false,
    }),
    [types.GET_PRODUCT_LIST_SEARCH_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // view product
    [types.REQUEST_VIEW_PRODUCT]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.VIEW_PRODUCT_SUCCEEDED]: (state, { data }) => ({
        ...state,
        productDetails: data,
        loading: false,
    }),
    [types.VIEW_PRODUCT_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),
});

export { types, actions };
