import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function Content({ data }) {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        //Animations
        let entryTl = gsap.timeline();
        entryTl.fromTo('section.legal-body .container h2', {}, {
            opacity: 1,
            y: 0,
            duration: 0.5
        })

        entryTl.fromTo('section.legal-body .container .text-content',{},{
            opacity:1,
            y:0,
            delay:0.2,
            duration:0.5
        },"<")
        return () => ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    }, []);

    return (
        <section className="legal-body">
            <div className="container">
                <h2 className="font-dark">
                    {data?.section_1.privacy_body.title}
                </h2>

                <div className="text-content">
                    {ReactHtmlParser(data?.section_1.privacy_body.description)}
                </div>
            </div>
        </section>
    );
};

export default Content;
