import BaseService from './BaseService';

class CrossReferenceService extends BaseService {
    getCrossReferenceProductList = () => this.get('/get-product-cross-references/?format=json');

    getCrossReferenceProductListWithQuery = (search) => this.get(`/get-product-cross-references/?search=${search}&format=json`);

    getCrossReferenceProductSuggestions = (search) => this.get(`/get-product-cross-references-suggestions/?search=${search}&format=json`);
}

export default new CrossReferenceService();
