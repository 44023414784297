import useAnimations from "../../../helpers/animations";

function Certifications({ data }) {
    useAnimations({
        usedFor:'entry-banner',
        sections: [
            {
                section: 'section.certifications-section',
                animations: [
                    { target: '.container .text-block' },
                    { target: '.container .certifications-wrapper .single-certification-wrapper .single-certification', stagger: 0.2 },
                ]
            }
        ]
    });

    return (
        <div className="certifications-wrapper d-flex">
            {data?.map((certification) => (
                (certification.display === 'B' || certification.display === 'C') &&
                    <div key={certification.id} className="single-certification-wrapper">
                        <div className="single-certification">
                            <div className="logo">
                                <a href={certification.button_link} target="_blank">
                                    <img src={certification.img_url_1} alt={`certification-${certification.id}`} />
                                </a>
                            </div>
                            <p>
                                {certification.description}
                            </p>
                        </div>
                    </div>
            ))}
        </div>
    )
}

export default Certifications;
