import ReactHtmlParser from 'react-html-parser';
import useAnimations from '../../helpers/animations';

function Certifications ({ data, certifications }) {
    useAnimations({
        sections: [{
            section: 'section.certifications-section',
            animations: [
                { target: '.container .text-block' },
                { target: '.container .certifications-wrapper .single-certification-wrapper .single-certification', stagger: 0.2 },
            ]
        }]
    });

    return (
        <section className="certifications-section bg-light">
            <div className="container">
                <div className="text-block">
                    <h1 className="font-dark">
                        {/* Our Certifications and <span>Memberships</span> */}
                        {ReactHtmlParser(data?.about_certifications.title)}
                    </h1>
                    <p>
                        {/* Our products meet or exceed all industry standards */}
                        {data?.about_certifications.description}
                    </p>
                </div>
                <div className="certifications-wrapper d-flex">
                    {certifications.map((certification) => (
                        (certification.display === 'B' || certification.display === 'C') &&
                            <div className="single-certification-wrapper" key={certification.id}>
                                <div className="single-certification">
                                    <div className="logo">
                                        <a href={certification.button_link} target="_blank">
                                            <img src={certification.img_url_1} alt={`certification-${certification.id}`} />
                                        </a>
                                    </div>
                                    <p>
                                        {certification.description}
                                    </p>
                                </div>
                            </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Certifications;