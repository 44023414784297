import { combineReducers } from 'redux';
import staticData from './static_data/reducer';
import menu from './menu/reducer';
import dynamicData from './dynamic_data/reducer';
import news from './news/reducer';
import contact from './contact/reducer';
import catalog from './catalog/reducer';
import crossReference from './cross_reference/reducer';

const appReducer = combineReducers({
    staticData,
    menu,
    dynamicData,
    news,
    contact,
    catalog,
    crossReference,
});

export default appReducer;

