const model = {
    homePage: null,
    mainPage: null,
    aboutPage: null,
    solutionsPage: null,
    faqsPage: null,
    technicalDataPage: null,
    contactPage: null,
    catalogModal: null,
    productsBySectorModal: null,
    catalogPage: null,
    privacyPage: null,
    termsAndConditionsPage: null,
    literaturePage: null,
    crossReferencePage: null,
    certificationsPage: null,
    newsPage: null,
    loading: false,
    error: null,
};

export default model;
