import { useSelector } from 'react-redux';
import ProductList from './ProductList';
import NoProductsFound from './NoProductsFound';

function MainContent({
    checkedCategories,
    setCheckedCategories,
    catalogUrlList,
}) {

    const productList = useSelector((state) => state.catalog.productList);
    const loading = useSelector((state) => state.catalog.productLoading);

    if (loading) {
        return <div className="loader" />;
    } else if (productList.length === 0) {
        return (
            <NoProductsFound />
        )
    } else {
        return (
            <ProductList
                data={productList}
                checkedCategories={checkedCategories}
                setCheckedCategories={setCheckedCategories}
                catalogUrlList={catalogUrlList}
            />
        );
    }
};

export default MainContent;