import ReactHtmlParser from 'react-html-parser';
import {
    ChevronCircleRightIcon,
    ChevronLeftDarkIcon,
    ChevronRightDarkIcon,
    ChevronRightDoubleDarkIcon,
    HeartLightLgIcon,
} from "../../../assets/icons";
import useAnimations from "../../../helpers/animations";

function ProductList({ data }) {
    useAnimations({
        sections: [
            {
                section: 'section.cross-reference-body',
                animations: [
                    { target: ' .container .search-results-blocks-wrapper .search-results-blocks .single-search-result', stagger: 0.5  },
                ]
            }
        ]
    });

    return (
        <div className="search-results-blocks-wrapper d-flex">
            {/* <div className="results-found">Displaying results 1 - 6</div> */}
            <div className="search-results-blocks d-flex">
                {data?.map((product) => (
                    <div className="single-search-result">
                        <div className="image"><img src={product.product_img_url} alt="product" /></div>
                        <div className="text-content">
                            <h3 className="font-dark">{product.product_name}</h3>
                            <p>{ReactHtmlParser(product.product_description)}</p>
                            <div className="cta d-flex">
                                {/* <div className="add-to-favorites">
                                    <HeartLightLgIcon />
                                </div>
                                <button className="outline-btn">
                                    <Link to="">
                                        <span>Add to Project</span>
                                        <ChevronCircleRightIcon />
                                    </Link>
                                </button> */}
                                <button className="view-product">
                                    <a
                                        href={`/catalog/product/${product.slug}?id=${product.product_id}&category=${product.categories[product.categories.length - 1].id}`}
                                    >
                                        <span>View Product</span>
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* <div className="search-results-footer d-flex">
                <div className="left">Your search for h-132 generated 20 results in 0.013s</div>
                <div className="pagination-wrapper">
                    <div className="pagination-block disabled">
                        <ChevronLeftDarkIcon />
                    </div>
                    <div className="pagination-block current">
                        1
                    </div>
                    <div className="pagination-block">
                        2
                    </div>
                    <div className="pagination-block">
                        3
                    </div>
                    <div className="pagination-block">
                        ...
                    </div>
                    <div className="pagination-block">
                        <ChevronRightDarkIcon />
                    </div>
                    <div className="pagination-block">
                        <ChevronRightDoubleDarkIcon />
                    </div>
                </div>
            </div> */}
        </div>
    )
};

export default ProductList;
