import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon, SearchDarkIcon, CloseMobileIcon } from "../../assets/icons";
import { actions } from "../../redux/catalog/reducer";
import { LogoLightIcon } from "../../assets";
import SearchResults from "../SearchResults";

function SearchModal() {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState(undefined);

    const productList = useSelector((state) => state.catalog.productListSearch);

    useEffect(() => {
        let timeoutId;

        if (searchValue) {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                dispatch(actions.requestGetProductListSearch(searchValue));
            }, 1000);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchValue]);

    document.getElementById('searchProducts')?.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    });

    return (
        <div className="search-modal">
            <div className="container">
                <div className="head d-flex">
                    <div className="logo">
                        <LogoLightIcon />
                    </div>
                    <div className="close" id="closeModal">
                        <CloseIcon />
                    </div>
                </div>
                <div className="search-block d-flex">
                    <div className="search-container">
                        <form
                            action=""
                            id="searchProducts"
                        >
                            <input
                                type="text"
                                placeholder="Search parts, catalog, products and more..."
                                name="search"
                                className="searchInput"
                                value={searchValue}
                                onChange={(e) => {setSearchValue(e.target.value)}}
                            />
                            {searchValue ?
                                <button
                                    type="button"
                                    className="clear"
                                    onClick={() => {
                                        setSearchValue('');
                                    }}
                                >
                                    <CloseMobileIcon />
                                </button>
                                :
                                <button
                                    type="submit"
                                    disabled
                                    className="submit"
                                >
                                    <SearchDarkIcon />
                                </button>
                            }
                        </form>
                        <SearchResults data={productList} />
                    </div>
                    {/* <div className="tags-wrapper d-flex">
                        <div className="label">Suggested tags:</div>
                        <div className="tags d-flex">
                            <div className="single-tag">H-Strut channels</div>
                            <div className="single-tag">H-112</div>
                            <div className="single-tag">H-112-KO</div>
                            <div className="single-tag">H-112-OS</div>
                            <div className="single-tag">H-112-OS</div>
                            <div className="single-tag">H-112-OS2.5</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default SearchModal;