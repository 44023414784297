import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import HeaderFixed from "../../../components/MainLayout/HeaderFixed";
import { updateTitle } from "../../../helpers/utils";
import CertificationsBanner from "./CertificationsBanner";
import Certifications from './Certifications';

function OurCertifications() {
    const [dataLoaded, setDataLoaded] = useState(false);

    const certificationPage = useSelector(state => state.staticData.certificationsPage);
    const certifications = useSelector(state => state.dynamicData.certifications);

    useEffect(() => {
        if (certifications.length !== 0) {
            setDataLoaded(true);
        }
    }, [certifications]);

    useEffect(() => {
        updateTitle('Haydon - Certifications');
    }, []);
    
    return (
        <div className="certifications-page">
            <HeaderFixed activeTab="resources" />
            <CertificationsBanner data={certificationPage?.section_1} />
            <section className="certifications-section bg-light">
                <div className="container">
                    <div className="text-block">
                        <h1 className="font-dark">
                            {ReactHtmlParser(certificationPage?.section_2.certifications_body.title)}
                        </h1>
                        <p>{certificationPage?.section_2.certifications_body.description}</p>
                    </div>
                    {dataLoaded && <Certifications data={certifications} />}
                </div>
            </section>
        </div>
    );
};

export default OurCertifications;
