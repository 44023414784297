import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import DynamicDataService from '../../services/DynamicDataService';
import { types, actions } from './reducer';

function* getSolutions() {
    try {
        const response = yield call(DynamicDataService.getSolutions);
        yield put(actions.getSolutionsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getSolutionsFailed(extractError(error)));
    }
}

function* getTeamMembers() {
    try {
        const response = yield call(DynamicDataService.getTeamMembers);
        const {
            board_members: boardMembers,
            executive_leadership: executiveLeadership,
            senior_leadership: seniorLeadership,
        } = response.data;
        yield put(actions.getTeamMembersSucceeded(
            boardMembers,
            executiveLeadership,
            seniorLeadership,
        ));
    } catch (error) {
        yield put(actions.getTeamMembersFailed(extractError(error)));
    }
}

function* getCertifications() {
    try {
        const response = yield call(DynamicDataService.getCertifications);
        yield put(actions.getCertificationsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getCertificationsFailed(extractError(error)));
    }
}

function* getFaqs({ filters }) {
    const { search } = filters;
    if (filters.search) {
        try {
            const response = yield call(DynamicDataService.getFaqsWithQuery, search);
            yield put(actions.getFaqsSucceeded(response.data));
        } catch (error) {
            yield put(actions.getFaqsFailed(extractError(error)));
        }
    } else {
        try {
            const response = yield call(DynamicDataService.getFaqs);
            yield put(actions.getFaqsSucceeded(response.data));
        } catch (error) {
            yield put(actions.getFaqsFailed(extractError(error)));
        }
    }
}

function* getFaqSuggestions({ filters }) {
    try {
        const response = yield call(DynamicDataService.getFaqSuggestions, filters);
        yield put(actions.getFaqSuggestionsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getFaqSuggestionsFailed(extractError(error)));
    }
}

function* getLiterature({ filters }) {
    const { search } = filters;
    if (filters.search) {
        try {
            const response = yield call(DynamicDataService.getLiteratureWithQuery, search);
            yield put(actions.getLiteratureSucceeded(response.data));
        } catch (error) {
            yield put(actions.getLiteratureFailed(extractError(error)));
        }
    } else {
        try {
            const response = yield call(DynamicDataService.getLiterature);
            yield put(actions.getLiteratureSucceeded(response.data));
        } catch (error) {
            yield put(actions.getLiteratureFailed(extractError(error)));
        }
    }
}

function* getLiteratureSuggestions({ filters }) {
    try {
        const response = yield call(DynamicDataService.getLiteratureSuggestions, filters);
        yield put(actions.getLiteratureSuggestionsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getLiteratureSuggestionsFailed(extractError(error)));
    }   
}

function* getTechnicalData({ filters }) {
    const { search } = filters;
    if (filters.search) {
        try {
            const response = yield call(DynamicDataService.getTechnicalDataWithQuery, search);
            yield put(actions.getTechnicalDataSucceeded(response.data));
        } catch (error) {
            yield put(actions.getTechnicalDataFailed(extractError(error)));
        }
    } else {
        try {
            const response = yield call(DynamicDataService.getTechnicalData);
            yield put(actions.getTechnicalDataSucceeded(response.data));
        } catch (error) {
            yield put(actions.getTechnicalDataFailed(extractError(error)));
        }
    }
}

function* getTechnicalDataSuggestions({ filters }) {
    try {
        const response = yield call(DynamicDataService.getTechnicalDataSuggestions, filters);
        yield put(actions.getTechnicalDataSuggestionsSucceeded(response.data));
    } catch (error) {
        yield put(actions.getTechnicalDataSuggestionsFailed(extractError(error)));
    }
}

function* downloadPdf({ data }) {
    try {
        yield call(DynamicDataService.downloadPdf, data);
        yield put(actions.downloadPdfSucceeded('Success'));
    } catch (error) {
        yield put(actions.downloadPdfFailed(extractError(error)));
    }
}

function* watchGetSolutions() {
    yield takeEvery(types.REQUEST_GET_SOLUTIONS, getSolutions);
}

function* watchGetTeamMembers() {
    yield takeEvery(types.REQUEST_GET_TEAM_MEMBERS, getTeamMembers);
}

function* watchGetCertifications() {
    yield takeEvery(types.REQUEST_GET_CERTIFICATIONS, getCertifications);
}

function* watchGetFaqs() {
    yield takeEvery(types.REQUEST_GET_FAQS, getFaqs);
}

function* watchGetFaqSuggestions() {
    yield takeEvery(types.REQUEST_GET_FAQ_SUGGESTIONS, getFaqSuggestions);
}

function* watchGetLiterature() {
    yield takeEvery(types.REQUEST_GET_LITERATURE, getLiterature);
}

function* watchGetLiteratureSuggestions() {
    yield takeEvery(types.REQUEST_GET_LITERATURE_SUGGESTIONS, getLiteratureSuggestions);
}

function* watchGetTechnicalData() {
    yield takeEvery(types.REQUEST_GET_TECHNICAL_DATA, getTechnicalData);
}

function* watchGetTechnicalDataSuggestions() {
    yield takeEvery(types.REQUEST_GET_TECHNICAL_DATA_SUGGESTIONS, getTechnicalDataSuggestions);
}

function* watchDownloadPdf() {
    yield takeEvery(types.REQUEST_DOWNLOAD_PDF, downloadPdf);
}

function* dynamicDataSaga() {
    yield all([
        fork(watchGetSolutions),
        fork(watchGetTeamMembers),
        fork(watchGetCertifications),
        fork(watchGetFaqs),
        fork(watchGetFaqSuggestions),
        fork(watchGetLiterature),
        fork(watchGetLiteratureSuggestions),
        fork(watchGetTechnicalData),
        fork(watchGetTechnicalDataSuggestions),
        fork(watchDownloadPdf),
    ]);
}

export default dynamicDataSaga;
