import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

const { Types: types, Creators: actions } = createActions({
    // get contact form
    requestGetContactForm: [],
    getContactFormSucceeded: ['contactForm'],
    getContactFormFailed: ['error'],

    // submit contact form
    requestSubmitContactForm: ['formData'],
    submitContactFormSucceeded: ['message'],
    submitContactFormFailed: ['error'],

    // clear success message
    clearMessage: [],
});

export default createReducer(initialState, {
    // get contact form
    [types.REQUEST_GET_CONTACT_FORM]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.GET_CONTACT_FORM_SUCCEEDED]: (state, { contactForm }) => ({
        ...state,
        contactForm,
        loading: false,
    }),
    [types.GET_CONTACT_FORM_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // submit contact form
    [types.REQUEST_SUBMIT_CONTACT_FORM]: (state) => ({
        ...state,
        loading: true,
    }),
    [types.SUBMIT_CONTACT_FORM_SUCCEEDED]: (state, { message }) => ({
        ...state,
        message,
        loading: false,
    }),
    [types.SUBMIT_CONTACT_FORM_FAILED]: (state, { error }) => ({
        ...state,
        error,
        loading: false,
    }),

    // clear success message
    [types.CLEAR_MESSAGE]: (state) => ({
        ...state,
        message: '',
    }),
});

export { types, actions };
