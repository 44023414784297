import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import { extractError } from '../../helpers/api';
import CatalogService from '../../services/CatalogService';
import { types, actions } from './reducer';

function* getCategories() {
    try {
        const response = yield call(CatalogService.getCategories);
        const {
            catalog: catalogCategories,
            url: urlCategories,
        } = response.data;
        yield put(actions.getCategoriesSucceeded(
            catalogCategories,
            urlCategories,
        ));
    } catch (error) {
        yield put(actions.getCategoriesFailed(extractError(error)));
    }
}

function* getProductList({ filters }) {
    try {
        const response = yield call(CatalogService.getProductList, filters);
        yield put(actions.getProductListSucceeded(response.data));
    } catch (error) {
        yield put(actions.getProductListFailed(extractError(error)));
    }
}

function* getProductListSearch({ filters }) {
    try {
        const response = yield call(CatalogService.getProductListSearch, filters);
        yield put(actions.getProductListSearchSucceeded(response.data));
    } catch (error) {
        yield put(actions.getProductListSearchFailed(extractError(error)));
    }
}

function* viewProduct({ data }) {
    try {
        const response = yield call(CatalogService.viewProduct, data);
        yield put(actions.viewProductSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewProductFailed(extractError(error)));
    }
}

function* watchGetCategories() {
    yield takeEvery(types.REQUEST_GET_CATEGORIES, getCategories);
}

function* watchGetProductsByCategory() {
    yield takeEvery(types.REQUEST_GET_PRODUCT_LIST, getProductList);
}

function* watchGetProductsByCategorySearch() {
    yield takeEvery(types.REQUEST_GET_PRODUCT_LIST_SEARCH, getProductListSearch);
}

function* watchViewProduct() {
    yield takeEvery(types.REQUEST_VIEW_PRODUCT, viewProduct);
}

function* catalogSaga() {
    yield all([
        fork(watchGetCategories),
        fork(watchGetProductsByCategory),
        fork(watchGetProductsByCategorySearch),
        fork(watchViewProduct),
    ]);
}

export default catalogSaga;
